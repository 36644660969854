import { injectable } from 'inversify';

export interface ILocalStore {
	get(key: string): any;
	set(key: string, value: any): void;
	remove(key: string): void;
}

@injectable()
export class LocalStorageStore implements ILocalStore {
	constructor() {}

	get(key: string): any {
		return localStorage.getItem(key);
	}

	set(key: string, value: any): void {
		localStorage.setItem(key, value);
	}

	remove(key: string): void {
		localStorage.removeItem(key);
	}
}
