import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { match } from 'react-router';
import { History } from 'history';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { IUtilities } from '../../core/utilities/Utilities';
import { Link } from 'react-router-dom';
const utilities = container.get<IUtilities>(TYPES.IUtilities);
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ILocalStore } from '../../core/utilities/LocalStore';
import Calendar from '../../assets/images/Calendar';
import DownArrow from '../../assets/images/DownArrow';
import SuiteModal from '../Common/SuiteModal';
import {
	EntityType,
	IShareholderInfoModel,
	K1Status,
	initialShareHolderInfoVModel,
} from '../../core/domain/viewModels/IShareholderInfoModel';
import { ISelectItem } from '../../core/domain/viewModels/ICommon';
import * as AccountStore from '../../store/Common/AccountStore';
import { actionCreators, IOTPState } from '../../store/Common/OTPStore';
import * as UserInfoStore from '../../store/UserInfoStore';
import * as WelcomeMessageStore from '../../store/Common/WelcomeMessageStore';
import { RouteComponentProps } from 'react-router';
import 'react-phone-input-2/lib/style.css';
import MyAccountPopUp from '../Home/MyAccountPopUp';

export type HeaderProps = {
	headerInfo: IHeaderInfoViewModel;
	match: match;
	history: History;
	shareHolderInfo: IShareholderInfoModel;
	otpState: IOTPState;
} & typeof AccountStore.actionCreators &
	typeof UserInfoStore.actionCreators &
	typeof WelcomeMessageStore.actionCreators &
	typeof actionCreators &
	RouteComponentProps<{}>;

export interface HeaderState {
	headerInfo: IHeaderInfoViewModel;
	showAccountPopup: boolean;
	shareHolderInfo: IShareholderInfoModel;
	isSaveDisabled: boolean;
	isSaveClicked: boolean;
	entityType: ISelectItem[];
	tempSSN: string;
}

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export class Header extends React.Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps, states: HeaderState) {
		super(props, states);
		this.state = {
			headerInfo: initialHeaderInfoViewModel,
			showAccountPopup: false,
			shareHolderInfo: initialShareHolderInfoVModel,
			isSaveDisabled: false,
			isSaveClicked: false,
			entityType: [],
			tempSSN: '',
		};
	}

	componentDidMount() {
		let param: any = this.props.match.params;
		if (localStorage.get('isLoggedIn')) {
			this.props.requestWelcomeMessageInfo(param.clientId);
			this.props.requestUserInfo(param.clientId);
			this.props.requestMFAOTPLength(param.clientId);
		} else {
			this.props.history.push(`/k1/ClientProfile/${localStorage.get('clientId')}`);
		}
	}

	static getDerivedStateFromProps(nextProps: HeaderProps, prevState: HeaderState) {
		let nullCheckHeader = true;
		let nullCheckAccount = true;
		let entityType: ISelectItem[] = [];
		let isSaveDisable: boolean = false;
		if (
			nextProps.headerInfo &&
			(nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !==
				prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode ||
				nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !==
					prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)
		) {
			nullCheckHeader = false;
			utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
		}

		if (prevState.shareHolderInfo.id == 0) {
			nullCheckAccount = false;
			for (let item in EntityType) {
				if (isNaN(Number(item))) {
					entityType.push({ key: item, value: item });
				}
			}

			if (nextProps.shareHolderInfo && nextProps.shareHolderInfo.pin) {
				if (nextProps.shareHolderInfo.status !== K1Status.Pending) {
					isSaveDisable = true;
				}
			}
		}

		if (nullCheckHeader && nullCheckAccount) {
			return null;
		} else {
			if (!nullCheckHeader) {
				return {
					headerInfo: nextProps.headerInfo,
				};
			}
			if (!nullCheckAccount) {
				return {
					shareHolderInfo: nextProps.shareHolderInfo,
					isSaveDisabled: isSaveDisable,
					entityType: entityType,
					isSaveClicked: false,
					tempSSN: nextProps.shareHolderInfo.pin,
					isMobileVerify: nextProps.shareHolderInfo.isMobileVerify,
					existingEmail: nextProps.shareHolderInfo.email,
				};
			}
		}
	}

	setIsSaveDisabled = () => {
		this.setState({ isSaveDisabled: true });
	};

	updateUserInfo = (shareHolderInfo: IShareholderInfoModel, isEmailChange: boolean, isMobileVerify: boolean) => {
		let param: any = this.props.match.params;
		let shareholderInfo = { ...shareHolderInfo };
		shareholderInfo.status = K1Status.Updated;
		shareholderInfo.isMobileVerify = isMobileVerify;
		this.setState({ isSaveClicked: true, shareHolderInfo: shareholderInfo });
		this.props.updateUserInfo(param.clientId, shareholderInfo, isEmailChange, this.setIsSaveDisabled());
	};
	onCancelClick = () => {
		let param: any = this.props.match.params;
		if (this.state.shareHolderInfo && this.state.shareHolderInfo.k1SendDate !== null) {
			this.setState({ showAccountPopup: false });
		} else {
			localStorage.remove('isLoggedIn');
			this.props.history.push(`/Authentication/Logout/${param.clientId}`);
		}
	};

	onSSNChange = (e: any) => {
		let _tempSSN = e;
		this.setState({ tempSSN: _tempSSN });
	};

	onMobileChangeText = (e: any) => {
		let shareHolderInfo: IShareholderInfoModel = {
			...this.state.shareHolderInfo,
		};
		let number = e.target.value;
		if (number.length <= 10 && !isNaN(number)) {
			shareHolderInfo.mobileNumber = number;
			this.setState({ shareHolderInfo: shareHolderInfo });
		}
	};
	handleBlurSSN = (event: any) => {
		let shareHolderInfo: IShareholderInfoModel = {
			...this.state.shareHolderInfo,
		};
		let ssn = this.state.tempSSN;
		let unmaskedSSN: string = ssn.replace(/[-]/g, '');
		shareHolderInfo.pin = unmaskedSSN;
		this.setState({ shareHolderInfo: shareHolderInfo });
	};

	getEntityOption = () => {
		let options: any[] = [];
		for (let item in EntityType) {
			if (isNaN(Number(item)) && item !== 'None') {
				options.push(<option>{item.toString()}</option>);
			}
		}
		return options;
	};

	Logout() {
		let param: any = this.props.match.params;
		localStorage.remove('isLoggedIn');
		this.props.history.push(`/Authentication/Logout/${param.clientId}`);
	}

	handleMyAccountClick = () => {
		this.setState({ showAccountPopup: true });
	};

	public render() {
		const accountPopover = (
			<Popover
				id='accountPopover'
				placement='bottom-start'>
				<div className='account-popover-body'>
					{this.props.headerInfo?.isShowProfile ? (
						<div className='account-menu'>
							<div
								className='profile-name'
								title={this.state.headerInfo.clientName}>
								Hi, {this.state.headerInfo.clientName}!
							</div>
							<div>
								<Link
									onClick={this.handleMyAccountClick}
									className='layout-dropdown-item my-account dropdown-item'
									to='#'
									data-test-auto='604745D7-0A6D-456C-A948-769E83F92ED0'>
									<span>My Account</span>
								</Link>
							</div>
							<hr className='dropdown-divider' />
							<div>
								<Link
									className='layout-dropdown-item log-out dropdown-item'
									to={'#'}
									onClick={() => this.Logout()}
									data-test-auto='4E53D021-3CA0-4A04-9C45-CD6EA21ADFF2'>
									<span> Logout</span>
								</Link>
							</div>
						</div>
					) : (
						<div className='account-menu'>
							<div>
								<Link
									className='layout-dropdown-item log-out dropdown-item'
									to={'#'}
									onClick={() => this.Logout()}
									data-test-auto='988F0655-9C57-4522-93A6-E39C2CF2B7F3'>
									<FontAwesomeIcon icon={faSignOutAlt} />
									<span> Logout</span>
								</Link>
							</div>
						</div>
					)}
				</div>
			</Popover>
		);

		return (
			<header className='app-header'>
				<div className='header-left-container'>
					<span
						className='company-name'
						data-test-auto='1EDA14C9-9660-4AB0-84BE-AA6CDD20D2C7'>
						{this.state.headerInfo.companyWhiteLogoPath.length === 0 ? (
							this.state.headerInfo.partnershipName
						) : (
							<img
								className='logo-img'
								src={this.state.headerInfo.companyWhiteLogoPath}
							/>
						)}
					</span>
				</div>
				<div className='header-right-container'>
					<span
						className='header-taxYear'
						data-test-auto='8BC7AFED-4716-44C9-9C3A-873F9C26C3AB'>
						<a
							data-test-auto='5B3409CB-FF7F-4B61-BCC7-711E63041873'
							className='taxyearCalendarWrapper'>
							<Calendar fillColor='var(--headerForeColor)' />
							Tax Year {this.state.headerInfo.taxYear}
						</a>
					</span>
					<span className='header-account'>
						<OverlayTrigger
							rootClose
							trigger='click'
							onEnter={() => document.body.click()}
							overlay={accountPopover}
							placement='bottom'>
							<a className='clientAccountNameWrapper'>
								{this.state.headerInfo.clientName} <DownArrow fillColor='var(--headerForeColor)' />
							</a>
						</OverlayTrigger>
					</span>
				</div>
				<SuiteModal
					show={this.state.showAccountPopup}
					width='475'
					theme='dark'
					title='Update K1 Beneficiaries Details'
					onClickOutside={() => {
						this.setState({ showAccountPopup: false });
					}}
					onClickClose={() => {
						this.setState({ showAccountPopup: false });
					}}
					className='k1MyAccountPopUp'>
					<MyAccountPopUp
						shareHolderInfo={this.props.shareHolderInfo}
						maxLength={this.props.otpState.mfaOTPLength}
						onCancelClick={this.onCancelClick}
						updateUserInfo={this.updateUserInfo}
						isSaveDisabled={this.state.isSaveDisabled}
						isSaveClicked={this.state.isSaveClicked}
						sendOTP={this.props.sendOTP}
						validateOTP={this.props.validateOTP}
						params={this.props.match.params}
						otpState={this.props.otpState}
					/>
				</SuiteModal>
			</header>
		);
	}
}
