export interface IUserModel {
	userId: number;
	firstName: string;
	middleName: string;
	lastName: string;
	phoneNumber: string;
	extension: string;
	ptin: string;
	email: string;
	faxNumber: string;
	title: string;
	createdOn?: Date;
	isDeceased: boolean;
}

export const initialUserModel: IUserModel = {
	userId: 0,
	firstName: '',
	middleName: '',
	lastName: '',
	phoneNumber: '',
	extension: '',
	ptin: '',
	email: '',
	faxNumber: '',
	title: '',
	isDeceased: false,
};
