import { PhobosNotifier } from '../Common/Notification/PhobosNotifier';
import { ValidationContants } from '../Common/Constants';

export function isValidEmailAddress(emailAddress: any) {
	var pattern = new RegExp(
		/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
	);
	return pattern.test(emailAddress);
}

export function ValidateTenDigitphoneNumber(value: string) {
	var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
	return pattern.test(value);
}

export function isValidTenDigitNumber(value: string) {
	const pattern = new RegExp(/^[0-9]{0,10}$/);
	return pattern.test(value);
}

export function phoneNumberDisplay(phoneNumber: string): string {
	return phoneNumber && phoneNumber.length == 10
		? '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10)
		: phoneNumber;
}

export function validatePhone(phoneNoId: any) {
	var phoneNo = phoneNoId.trim();
	if (phoneNo.trim() == '') {
		PhobosNotifier.Warning(ValidationContants.PhoneNumberWarning);
		return false;
	} else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
		PhobosNotifier.Warning(ValidationContants.PhoneNumberLengthWarning);
		return false;
	} else return true;
}
