import * as React from 'react';

interface ICountdownProps {
	date: Date;
	onComplete: () => void;
}
interface ICountdownState {
	days: number;
	hours: number;
	min: number;
	sec: number;
}
export class Countdown extends React.Component<ICountdownProps, ICountdownState> {
	public interval: any;
	constructor(props: ICountdownProps) {
		super(props);
		this.interval = null;
		this.state = {
			days: 0,
			hours: 0,
			min: 0,
			sec: 0,
		};
	}

	componentDidMount() {
		// update every second
		this.interval = setInterval(() => {
			const date = this.calculateCountdown(this.props.date);
			date ? this.setState(date) : this.stop();
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	private calculateCountdown = (endDate: any) => {
		const now: any = new Date();
		let diff: number = Math.floor((endDate - now) / 1000);

		// clear countdown when date is reached
		if (diff <= 0) return false;

		const timeLeft = {
			years: 0,
			days: 0,
			hours: 0,
			min: 0,
			sec: 0,
			millisec: 0,
		};

		// calculate time difference between now and expected date
		if (diff >= 365.25 * 86400) {
			// 365.25 * 24 * 60 * 60
			timeLeft.years = Math.floor(diff / (365.25 * 86400));
			diff -= timeLeft.years * 365.25 * 86400;
		}
		if (diff >= 86400) {
			// 24 * 60 * 60
			timeLeft.days = Math.floor(diff / 86400);
			diff -= timeLeft.days * 86400;
		}
		if (diff >= 3600) {
			// 60 * 60
			timeLeft.hours = Math.floor(diff / 3600);
			diff -= timeLeft.hours * 3600;
		}
		if (diff >= 60) {
			timeLeft.min = Math.floor(diff / 60);
			diff -= timeLeft.min * 60;
		}
		timeLeft.sec = Math.floor(diff);

		return timeLeft;
	};

	private stop = () => {
		clearInterval(this.interval);
		this.props.onComplete();
	};

	private addLeadingZeros = (value: number) => {
		let strVal = String(value);
		while (strVal.length < 2) {
			strVal = '0' + value;
		}
		return strVal;
	};

	public render() {
		const countDown = this.state;

		return (
			<strong>
				{this.addLeadingZeros(countDown.min)}:{this.addLeadingZeros(countDown.sec)}
				{'. '}
			</strong>
		);
	}
}

export default Countdown;
