const TYPES = {
	IDataService: Symbol.for('IDataService'),
	ITaxDocumentService: Symbol.for('ITaxDocumentService'),
	ILocalStore: Symbol.for('ILocalStore'),
	ITextUtilities: Symbol.for('ITextUtilities'),
	IWindowUtilities: Symbol.for('IWindowUtilities'),
	IFileClient: Symbol.for('IFileClient'),
	IHtmlUtilities: Symbol.for('IHtmlUtilities'),
	IDialogBox: Symbol.for('IDialogBox'),
	IRegexUtilities: Symbol.for('IRegexUtilities'),
	IUtilities: Symbol.for('IUtilities'),
	IFileUploadUtilities: Symbol.for('IFileUploadUtilities'),
	IFileUtilities: Symbol.for('IFileUtilities'),
	IJsonUtilities: Symbol.for('IJsonUtilities'),
};

export { TYPES };
