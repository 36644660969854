import React from 'react';

const SessionTimeoutSVG = () => {
	return (
		<svg
			version='1.1'
			id='Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x='0px'
			y='0px'
			width='192px'
			height='192px'
			viewBox='0 19 192 192'
			enableBackground='new 0 19 192 192'>
			<path
				fill='#F7C001'
				d='M-323-93'
			/>
			<g>
				<path
					fill='#F8F8F8'
					d='M96,207c-50.729,0-92.001-41.271-92.001-92c0-50.729,41.272-92,92.001-92s92.001,41.271,92.001,92
		C188.001,165.729,146.729,207,96,207L96,207z M75.27,66.749C56.591,74.805,43.484,93.4,43.484,115
		c0,28.957,23.558,52.516,52.516,52.516c28.959,0,52.517-23.559,52.517-52.516c0-21.6-13.107-40.195-31.786-48.251
		c0.198,1,0.302,2.033,0.302,3.09v39.601l16.607,12.083c3.438,2.483,5.704,6.193,6.349,10.423c0.633,4.152-0.378,8.317-2.844,11.729
		l-6.078,8.374c-3.028,4.113-7.813,6.53-12.846,6.53c-3.364,0-6.586-1.042-9.314-3.016l-27.51-19.993
		c-4.025-2.986-6.43-7.758-6.43-12.764V69.839C74.967,68.782,75.071,67.749,75.27,66.749L75.27,66.749z'
				/>
				<linearGradient
					id='SVGID_1_'
					gradientUnits='userSpaceOnUse'
					x1='16'
					y1='115'
					x2='176'
					y2='115'>
					<stop
						offset='0'
						style={{ stopColor: '#F7C001' }}
					/>
					<stop
						offset='1'
						style={{ stopColor: '#E77A17' }}
					/>
				</linearGradient>
				<path
					fill='url(#SVGID_1_)'
					d='M96,35c-44.193,0-80,35.807-80,80s35.807,80,80,80s80-35.807,80-80S140.193,35,96,35z M96,179.516
		c-35.646,0-64.516-28.87-64.516-64.516c0-35.645,28.87-64.516,64.516-64.516S160.517,79.355,160.517,115
		C160.517,150.646,131.646,179.516,96,179.516z M115.936,145.839l-27.388-19.902c-1.001-0.744-1.58-1.904-1.58-3.13V69.839
		c0-2.13,1.741-3.871,3.871-3.871h10.322c2.13,0,3.871,1.741,3.871,3.871v45.71l21.548,15.677c1.742,1.259,2.098,3.678,0.84,5.42
		L121.355,145C120.096,146.71,117.677,147.097,115.936,145.839L115.936,145.839z'
				/>
			</g>
		</svg>
	);
};

export default SessionTimeoutSVG;
