import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';
import { actionTypes } from './ActionTypes';
import { StatusType } from './Common/NotificationStore';
import { AppThunkAction } from './index';
import { HeaderConstants } from '../components/Common/Constants';
import { logger } from '../routes';

interface IThemeSettings {
	bgColorCode: string;
	foreColorCode: string;
}

interface IBrandingSettings {
	coverPageSetting: IThemeSettings;
}

interface ILoggedInUserInfo {
	role: string;
	permission: string;
}

export interface IBaseViewModel {
	documentId: number;
	shareholderName: string;
	taxYear: number | string;
	companyName: null | string;
	companyLogoPath: string;
	companyWhiteLogoPath: string;
	brandingSettings: {
		coverPageSetting: {
			bgColorCode: string;
			foreColorCode: string;
		};
	} | null;
}

export const initialHeaderInfo: IBaseViewModel = {
	shareholderName: '',
	taxYear: '',
	companyName: '',
	documentId: 0,
	brandingSettings: null,
	companyLogoPath: '',
	companyWhiteLogoPath: '',
};

export const actionCreators = {
	requestHeaderDetails:
		(clientId?: string): AppThunkAction<any> =>
		(dispatch, getState) => {
			return initializeAxios()
				.get<any>('/api/K1/GetHeaderInfo/' + clientId, { withCredentials: true })
				.then(function (response: AxiosResponse<any>) {
					let result = response.data;
					if (result.isSuccess) {
						dispatch({
							type: actionTypes.RECEIVE_HEADER_DETAILS,
							data: result.data,
						});
					} else {
						let errorDesc = result.errorDescription;

						if (typeof errorDesc === 'string') {
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: errorDesc,
								statusType: StatusType.Error,
							});
							logger.trackWarning(
								`requestHeaderDetails --> Error occurred with error description: ${errorDesc} for clientId: ${clientId}`,
							);
						} else {
							logger.trackError(
								`requestHeaderDetails --> Error occurred with error description: ${errorDesc} for clientId: ${clientId}`,
							);
						}
					}
				})
				.catch((e: any) => {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`requestHeaderDetails --> Error occurred with error description: ${e.message} for clientId: ${clientId}`,
					);
				});
		},
};

export const reducer: Reducer<IBaseViewModel> = (state: IBaseViewModel = initialHeaderInfo, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log('in reducers', action);
	switch (action.type) {
		case actionTypes.RECEIVE_HEADER_DETAILS:
			let rData = action.data;
			currentState.shareholderName = rData.shareholderName;
			currentState.taxYear = rData.taxYear;
			currentState.companyName = rData.companyName;
			currentState.documentId = rData.documentId;
			currentState.brandingSettings = rData.brandingSettings;
			currentState.companyLogoPath = rData.companyLogoPath;
			currentState.companyWhiteLogoPath = rData.companyWhiteLogoPath;

			return { ...currentState };

		default:
			return currentState;
	}
};
