import { injectable } from 'inversify';
import * as bootbox from 'bootbox';

export interface IDialogBox {
	confirm(title: string, message: string, callback?: (result: boolean) => void): void;
}

@injectable()
export class DialogBox implements IDialogBox {
	confirm(title: string, message: string, callback?: (result: boolean) => void): void {
		bootbox.confirm({
			title: '<i className="text-secondary"></i>' + title,
			message: message,
			buttons: {
				cancel: {
					label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
					className: 'btn-white btn-default',
				},
				confirm: {
					label: '<i class="glyphicon glyphicon-ok"></i> Confirm',
					className: 'btn-primary',
				},
			},
			callback: (result: boolean) => {
				if (callback) {
					callback(result);
				}
			},
		});
	}
}
