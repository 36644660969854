import * as React from 'react';

import { Toaster } from './Common/Notification/PhobosNotifier';
import Notification from './Common/Notification/NotificationContainer';

export type HeaderProps = {
	headerInfo: any;
};

export class HeaderComp extends React.Component<HeaderProps, any> {
	popRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			showPop: false,
		};

		this.popRef = React.createRef();
	}

	componentDidMount() {}

	componentDidUpdate() {}

	componentWillUnmount() {}

	public render() {
		let headerBGColor = '#2bcb15';
		let headerColorCode = '#e41c11';

		let companyLogo = null;

		if (this.props.headerInfo.brandingSettings) {
			headerBGColor = this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode;
			headerColorCode = this.props.headerInfo.brandingSettings.coverPageSetting.foreColorCode;
		}

		let shareHolderName = this.props.headerInfo.shareholderName;

		companyLogo =
			this.props.headerInfo.companyWhiteLogoPath?.length === 0 ? (
				<span
					className='company-name'
					style={{ color: headerColorCode }}>
					{shareHolderName !== null ? shareHolderName : ''}
				</span>
			) : (
				<img
					className='logo-img'
					src={this.props.headerInfo.companyWhiteLogoPath}
				/>
			);

		return (
			<>
				<Notification />
				<Toaster />
				<header
					className='app-header'
					style={{
						backgroundColor: headerBGColor,
						color: headerColorCode,
						position: 'absolute',
						width: '100%',
					}}>
					<div
						className='header-left-container'
						data-test-auto='951602DF-76D9-480A-BA0F-D12E216FBB2B'>
						{companyLogo}
					</div>
					<div
						className='header-right-container'
						data-test-auto='710F957F-072E-4A27-867F-0B40C2077D3B'>
						<span
							className='header-contact-info'
							style={{ color: headerColorCode }}
							title="Contact Person's Information"></span>
					</div>
				</header>
			</>
		);
	}
}
export default HeaderComp;
