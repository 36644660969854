export interface IShareholderInfoModel {
	id: number;
	name: string;
	pin: string;
	address: string;
	email: string;
	entity: EntityType;
	countryCode: string;
	mobileNumber: string;
	modifiedOn?: Date;
	status: K1Status;
	documentId: number;
	shareholderId: number;
	isK1Decline: boolean;
	k1DownloadDate?: Date;
	k1SendDate?: Date;
	isMobileVerify: boolean;
}

export enum K1Status {
	None = 0,
	Updated = 1,
	Confirmed = 2,
	Pending = 3,
}
export enum EntityType {
	None = 0,
	Individual = 1,
	Entity = 2,
}

export const initialShareHolderInfoVModel: IShareholderInfoModel = {
	id: 0,
	name: '',
	pin: '',
	address: '',
	email: '',
	entity: EntityType.None,
	countryCode: '',
	mobileNumber: '',
	status: K1Status.None,
	documentId: 0,
	shareholderId: 0,
	isK1Decline: false,
	isMobileVerify: false,
};
