import * as React from 'react';
export class Disclosure extends React.Component<{}, {}> {
	constructor(props: any) {
		super(props);
	}
	public render() {
		return (
			<section className='disclosuresContainer'>
				<h4
					className='disclosuresHeaderText'
					data-test-auto='EC6ABA59-95F6-472E-9791-26C3DF3D2120'>
					Disclosures
				</h4>
				<ol>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Paper statement: </span>
							<span>
								{' '}
								The Schedule K-1 will be furnished on paper if you do not consent to receive it electronically.{' '}
							</span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Scope and duration of consent: </span>
							<span> Consent will be requested every time a Schedule K-1 is delivered to you electronically.</span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Post-consent request for paper statement: </span>
							<span>
								{' '}
								If you would like to request a paper copy of your Schedule K-1 after you have already consented to
								electronic delivery, you must provide a written request via mail, email or fax.{' '}
							</span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresBoldText'> Withdrawal of consent: </span>
							<span>
								{' '}
								You may withdraw your consent by providing a written request (electronically or on paper). The
								withdrawal of consent will take effect the day after it is received. Your withdrawal request will be
								confirmed in writing (electronically or on paper) within 7 business days.{' '}
							</span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Notice of termination: </span>
							<span>
								{' '}
								We will cease furnishing your K-1’s electronically if you are no longer entitled to receive a K-1 from
								the partnership or corporation.{' '}
							</span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Updating information: </span>
							<span> Please submit any change in contact information via mail, email or fax. </span>
						</div>
					</li>
					<li>
						<div className='prepared-by-title'>
							<span className='disclosuresSemiBoldText'> Hardware and software requirements: </span>
							<span>
								{' '}
								Your Schedule K-1 is being delivered in PDF format. You will need a PDF reader or editor (such as Adobe
								Reader) to open and view the file. The files will remain available on this website in accordance with
								our tax preparers document retention policy, which is normally a minimum of 1 year. The Schedule K-1 may
								be required to be printed and attached to a Federal, State or Local income tax return.{' '}
							</span>
						</div>
					</li>
				</ol>
			</section>
		);
	}
}
