import * as React from 'react';
import { Header } from './Header';
import Notification from '../Common/Notification/NotificationContainer';
import { Toaster } from '../../components/Common/Notification/PhobosNotifier';
import { RouteComponentProps } from 'react-router';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { match } from 'react-router';
import { History } from 'history';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { SessionTimeout } from '../Account/SessionTimeout';
import { initializeAppInsights } from '../../Logger/AppInsights';
import { IShareholderInfoModel } from '../../core/domain/viewModels/IShareholderInfoModel';
import * as AccountStore from '../../store/Common/AccountStore';
import { actionCreators, IOTPState } from '../../store/Common/OTPStore';
import * as UserInfoStore from '../../store/UserInfoStore';
import * as WelcomeMessageStore from '../../store/Common/WelcomeMessageStore';
import Footer from './Footer';

export type HeaderProps = {
	headerInfo: IHeaderInfoViewModel;
	sessionIdleTime: number;
	match: match;
	history: History;
	shareHolderInfo: IShareholderInfoModel;
	otpState: IOTPState;
} & typeof AccountStore.actionCreators &
	typeof UserInfoStore.actionCreators &
	typeof WelcomeMessageStore.actionCreators &
	typeof actionCreators &
	RouteComponentProps<{}>;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export default class DefaultLayout extends React.Component<React.PropsWithChildren<HeaderProps>, {}> {
	constructor(props: HeaderProps, states: any) {
		super(props, states);
	}

	componentDidMount() {
		let param: any = this.props.match.params;
		initializeAppInsights(param.clientId);
		this.props.requestHeaderInfo(param.clientId);
	}

	public render() {
		return (
			<div>
				<Header {...this.props} />
				<div
					id='content-wrapper'
					className='content-wrapper'>
					<Notification />
					{this.props.children}
				</div>
				<Footer />
				<Toaster />
				<SessionTimeout
					match={this.props.match}
					refreshToken={this.props.refreshToken}
					sessionIdleTime={this.props.sessionIdleTime}
				/>
			</div>
		);
	}
}
