import React from 'react';
import { SvgIconAccessCode } from '../../assets/images/SVGIcons';
import OtpInputControl from '../Common/OTPInputControl';

interface OTPMobileProps {
	match: any;
	history: any;
	headerInfoState: any;
	OTPPageState: any;
	otpVal: string;
	continueState: boolean;
	isInitialOTPSent: boolean;
	otpError: {
		error: boolean;
		message: string;
	};
	handleOTPChange: (otp: string) => void;
	requestCode: () => void;
	verifyOTP: () => void;
}

export class OTPMobile extends React.Component<OTPMobileProps, any> {
	continueRef: any = null;

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {}

	render() {
		let continueClass = 'btn btn-primary rounded-pill disabled float-right otpBlueButton';
		if (this.props.continueState) {
			continueClass = 'btn btn-primary rounded-pill float-right otpBlueButton';
			this.continueRef.focus();
		}

		let phoneNo: string = this.props.OTPPageState.mobileNo;
		let lastNum: string = '';

		if (phoneNo && phoneNo.length !== 0) {
			lastNum = '{xxx}xxx-' + phoneNo.substr(6);
		}

		return (
			<>
				<div className='layout-container d-flex align-items-center justify-content-center'>
					<div className='row'>
						<div className='col'>
							<div className='row justify-content-center'>
								<SvgIconAccessCode className='otpSVGHeaderIcon' />
							</div>
							<div className='row justify-content-center margin-top-20'>
								<h5 className='otp-page-title'>Request Access Code</h5>
							</div>
							<div className='row justify-content-center'>
								{!this.props.isInitialOTPSent ? (
									<div
										className='text-center'
										data-test-auto='ABDA293F-1EE9-4F6C-8958-CDA18D7C4FD9'
										style={{ width: '65%' }}>
										<p className='otpTextMessage'>We will send you a One-Time Access Code to your mobile number.</p>
									</div>
								) : (
									<div
										className='text-center'
										data-test-auto='51B29EAF-4CE6-41BD-8B2D-F02652B09E41'
										style={{ width: '65%' }}>
										<p className='otpTextMessage'>
											A One-Time Access Code has been sent to your mobile number: {lastNum}
										</p>
										<p className='otpExpiryTimeLimit'>Expires in 20 minutes.</p>
									</div>
								)}
							</div>
							{!this.props.isInitialOTPSent && (
								<div className='row justify-content-center margin-top-20'>
									<div>
										<button
											type='button'
											data-test-auto='D8D9D03F-F300-4705-ABE3-76AB53AAA9B2'
											className='btn btn-primary rounded-pill otpBlueButton'
											style={{ width: 250, height: 56 }}
											id='btnRequestAccessCode'
											onClick={this.props.requestCode}>
											Send Code
										</button>
									</div>
								</div>
							)}

							{this.props.isInitialOTPSent && (
								<>
									<div
										className='row justify-content-center otp-page-container-input-div margin-top-20'
										data-test-auto='BB058EE1-6A10-4B21-81F5-0BF6D4AE7BB0'>
										<OtpInputControl
											otp={this.props.otpVal}
											setOtp={this.props.handleOTPChange}
											isInvalid={this.props.otpError.error}
											errorText={this.props.otpError.message}
											numInputs={6}
										/>
									</div>
									<div className='row justify-content-center margin-top-20 margin-bottom-16'>
										<div className='col-flex align-items-center text-center'>
											<div className='spam-text'>If you did not receive it yet, check your junk/spam.</div>
											<button
												className='btn-link resend-btn'
												data-test-auto='1C636E58-5B57-4781-94DD-626DCEDACCF2'
												id='btn-resend-code'
												onClick={this.props.requestCode}>
												Resend Code
											</button>
										</div>
									</div>
									<div className='row justify-content-center'>
										<div className='margin-top-20'>
											<button
												ref={(ref) => {
													this.continueRef = ref;
												}}
												type='submit'
												id='btnMFAContinue'
												data-test-auto='143BA7E4-375C-4AD6-8784-8562AA038C5B'
												className={continueClass}
												style={{ width: 250, height: 56 }}
												onClick={this.props.verifyOTP}>
												Confirm
											</button>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default OTPMobile;
