import * as React from 'react';
import Select from 'react-select';

export interface ISelectComponentProps {
	selectedValue?: any;
	options: any;
	onChange?: (selectedValue: any) => void;
	clearable?: boolean;
	disabled?: boolean;
	customPlaceHolder?: any;
	id: string;
	onFocus?: (event: any) => void;
	testAttribute?: any;
	menuHeight?: number;
	className?: string;
}

export class SelectComponent extends React.Component<ISelectComponentProps, {}> {
	constructor(props: any) {
		super(props);
	}

	private onChange = (selectedValue: any) => {
		if (this.props.onChange) {
			this.props.onChange(selectedValue ? selectedValue.value : selectedValue);
		}
	};

	public render() {
		let selectedValue =
			this.props.selectedValue &&
			this.props.options.find((option: any) => option.value === this.props.selectedValue.toString());

		return (
			<Select
				id={this.props.id}
				value={selectedValue}
				onChange={this.onChange}
				options={this.props.options}
				placeholder={
					this.props.customPlaceHolder && this.props.customPlaceHolder.length > 0
						? this.props.customPlaceHolder
						: 'Select...'
				}
				isClearable={this.props.clearable == undefined ? false : this.props.clearable}
				data-toggle='dropdown'
				data-boundary='window'
				onFocus={this.props.onFocus}
				data-test-auto={this.props.testAttribute}
				isDisabled={this.props.disabled ? this.props.disabled : false}
				maxMenuHeight={this.props.menuHeight ? this.props.menuHeight : 220}
				className={this.props.className}
				classNamePrefix={this.props.className}
			/>
		);
	}
}
