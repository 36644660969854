import 'babel-polyfill';
import 'bootstrap';
import { ConnectedRouter } from 'connected-react-router';
import 'isomorphic-fetch';
import * as React from 'react';
import { Provider } from 'react-redux';
import { history } from './core/services/dataAccess/History';
import { ApplicationState } from './store';
import configureStore from './configureStore';
import * as RoutesModule from './routes';
import './assets/css/site.css';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

let routes = RoutesModule.routes;
const initialState = (window as any).initialReduxState as ApplicationState;
const store = configureStore(history, initialState);

function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter
				children={routes}
				history={history}
			/>
		</Provider>
	);
}

export default App;

