import * as React from 'react';
import { NotificationState, StatusType } from '../../../store/Common/NotificationStore';
import { PhobosNotifier } from './PhobosNotifier';

type NotificationProps = {
	notification: NotificationState;
};

export class Notification extends React.Component<NotificationProps, any> {
	componentWillReceiveProps(nextProps: NotificationProps) {
		if (nextProps.notification.type == StatusType.Success) {
			PhobosNotifier.Success(nextProps.notification.message);
		} else if (nextProps.notification.type == StatusType.Error) {
			PhobosNotifier.Error(nextProps.notification.message);
		} else if (nextProps.notification.type == StatusType.Warning) {
			PhobosNotifier.Warning(nextProps.notification.message);
		} else {
			PhobosNotifier.Info(nextProps.notification.message);
		}
	}
	render() {
		return <div />;
	}
}
