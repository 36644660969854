import { connect } from 'react-redux';
import OTPPage from './OTPPage';
import * as OTPPageReducer from '../../../store/Common/AuthStore';
import * as HeaderStore from '../../../store/HeaderStore';
import { ApplicationState } from '../../../store';
import { bindActionCreators } from 'redux';

export default connect(
	(state: ApplicationState) => ({
		headerInfoState: state.HeaderState,
		OTPPageState: state.AuthState,
	}),
	(dispatch: any) => ({
		...bindActionCreators({ ...OTPPageReducer.actionCreators, ...HeaderStore.actionCreators }, dispatch),
	}),
)(OTPPage as any);
