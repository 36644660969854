import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import Consent from './../K1Download/ConsentModal';
import Download from './../K1Download/Download';
import { actionCreators as K1DownloadActionCreator } from './../../store/K1Download/K1DownloadStore';
import { K1DownloadStoreState, ConsentStatus } from './../../core/domain/viewModels/IK1DownloadViewModel';
import { container } from '../../startup/inversify.config';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { TYPES } from '../../startup/types';
import { DownloadFile } from '../Common/DownloadFile';

type MainComponentProps = {
	match: match;
	k1Download: K1DownloadStoreState;
	taxYear: number;
	k1Name: string;
	isK1Declained: boolean;
} & RouteComponentProps<{}> &
	typeof K1DownloadActionCreator;

interface MainComponentState {
	isNextButtonDisable: boolean;
	showConsent: boolean;
}

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export class ShareHolderFlow extends React.Component<MainComponentProps, MainComponentState> {
	constructor(props: MainComponentProps) {
		super(props);
		this.state = {
			isNextButtonDisable: true,
			showConsent: true,
		};
	}
	componentDidMount() {
		const param: any = this.props.match.params;
		if (localStorage.get('isLoggedIn')) {
			const { getInstructionName, getK1ConsentStatus, getK1AttachmentDetails } = this.props;
			getInstructionName(param.clientId);
			getK1ConsentStatus(param.clientId);
			getK1AttachmentDetails(param.clientId);
		} else {
			this.props.history.push(`/k1/Download/${localStorage.get('clientId')}`);
		}
	}

	downloadK1Attachment = (uri: string, fileName?: string) => {
		let File = new DownloadFile();
		File.directDownload(uri, fileName);
	};

	closeConsent = () => {
		this.setState({
			showConsent: false,
		});
	};

	LogOut = () => {
		let param: any = this.props.match.params;
		localStorage.remove('isLoggedIn');
		window.location.href = '/Authentication/Logout/' + param.clientId;
	};

	public render() {
		const param: any = this.props.match.params;
		const { showConsent } = this.state;
		const {
			dowloadK1Package,
			downloadK1Instruction,
			k1Download: { InstructonFileName, instructionLoader, packageLoader, consentStatus, k1Attachments },
			k1Name,
			taxYear,
			updateConsent,
		} = this.props;

		let isShowConsent = (k1Name && k1Name !== '') || (InstructonFileName && InstructonFileName != '') ? true : false;
		isShowConsent = isShowConsent && this.state.showConsent && consentStatus !== ConsentStatus.Agreed;

		return (
			<>
				<Consent
					onAccept={() => {
						updateConsent(param.clientId, false, this.closeConsent);
					}}
					show={isShowConsent}
					onDecline={() => {
						updateConsent(param.clientId, true, this.LogOut);
					}}
				/>
				<Download
					downloadK1Instruction={downloadK1Instruction}
					downloadK1Package={dowloadK1Package}
					downloadK1Attachment={this.downloadK1Attachment}
					k1Instruction={InstructonFileName}
					k1Attachments={k1Attachments}
					k1Name={k1Name}
					taxyear={taxYear}
					param={this.props.match}
					instructionLoader={instructionLoader}
					packageLoader={packageLoader}
				/>
			</>
		);
	}
}
