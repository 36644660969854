import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import SuiteModal from '../Common/SuiteModal';

interface ConsentProps {
	show: boolean;
	onAccept: () => void;
	onDecline: () => void;
}
const Consent: React.FunctionComponent<ConsentProps> = (props) => {
	return (
		<SuiteModal
			show={props.show}
			width='750'
			theme='light'
			title='K-1 Consent'
			onClickOutside={() => {}}
			className='k1ConsentPopUp'
			hideCloseIcon={true}>
			<>
				<Modal.Body>
					<div className='innerHeader'>
						Pursuant to IRS guidelines (Revenue Procedure 2012-17), we must now gather your explicit consent to
						electronically provide documents with tax information (K-1s).
					</div>
					<div>
						<div className='agreeHeader'>IF YOU AGREE</div>
						<div className='bulletPointsAgree'>
							<ol>
								<li>Your agreement to receive your tax information (K-1) electronically is required.</li>
								<li>
									This consent is a single-use authorization, applicable exclusively to the ongoing tax season.
									Subsequent deliveries of tax information will necessitate renewed consent.
								</li>
								<li>Your tax information will remain accessible until the conclusion of the current calendar year.</li>
								<li>Please promptly notify your provider of any changes to your contact information.</li>
								<li>
									All tax information is furnished in PDF format. You will need a PDF viewer to access and view your
									K-1.
								</li>
							</ol>
						</div>
						<div className='disagreeHeader'>IF YOU DECLINE</div>
						<div className='bulletPointsDisagree'>
							<ol>
								<li>You have chosen not to provide electronic consent for receiving your tax information (K-1).</li>
								<li>Your provider will be informed of your request for a paper copy of your tax information.</li>
								<li>
									Your decision to decline electronic consent applies exclusively to the current tax season. If you wish
									to continue declining electronic delivery for future tax information, additional decline will be
									necessary.
								</li>
							</ol>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className='k1ConsentDeclineButton'
						variant={'primary'}
						onClick={props.onDecline}>
						I Decline
					</Button>
					<Button
						className='k1ConsentAcceptButton'
						variant={'primary'}
						onClick={props.onAccept}>
						I Agree
					</Button>
				</Modal.Footer>
			</>
		</SuiteModal>
	);
};

export default Consent;
