import axios, { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import { IDataService } from './abstraction/IDataService';
import { history } from './History';
import { TelemetryLogger } from '../../../Logger/AppInsights';
import { API_BASE_URL } from 'src/components/Common/Constants';

export const inMemoryToken: Map<string, string> = new Map();

@injectable()
export class AxiosDataService implements IDataService {
	private _baseUri: string = API_BASE_URL;
	private logger: TelemetryLogger;
	private clientId: string = '';
	private actionName: string = '';

	constructor(clientId?: string) {
		this.clientId = clientId ? clientId : '';
		this.logger = TelemetryLogger.getInstance();
		let self = this;

		if (clientId && inMemoryToken.has(clientId)) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + inMemoryToken.get(clientId);
		}

		axios.defaults.withCredentials = true;
		axios.interceptors.request.use(
			function (config: any) {
				return config;
			},
			function (error: any) {
				return Promise.reject(error);
			},
		);

		axios.interceptors.response.use(
			function (response: any) {
				self.actionName = response?.config.url;
				if (response.status === 401) {
					//redirect to login url
					history.push('/invalid');
					self.logger.trackWarning(`${self.actionName} failed for client: ${self.clientId}, error: UnAuthorized`);
					throw new Error('UnAuthorized');
				} else if (response.status === 200) {
					if (response?.data === 'invalidlink') {
						history.push('/invalid');
						throw new Error('Invalid link');
					}
					self.logger.trackTrace(`${self.actionName} succeeded for client: ${self.clientId}`);
				}
				return response;
			},
			function (error: any) {
				if (!error.config?.__isRetryRequest && error.response) {
					self.actionName = error.response.config?.url;

					if (error.response.status == '400' || error.response.status == '401' || error.response.status == '403') {
						self.logger.trackWarning(
							`${self.actionName} failed for client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
						);
						history.push('/invalid');
					} else if (error.response.status == '307') {
						self.logger.trackWarning(
							`${self.actionName} failed for client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
						);
						window.location.href = error.response.data;
					} else if (error.response.status == '418') {
						self.logger.trackWarning(
							`${self.actionName} failed for client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
						);
					} else {
						self.logger.trackError(
							`Request failed for ${self.actionName}, client: ${self.clientId}, response: ${
								error.response.data
							}, errorCode: ${error.response.status}, errorMessage: ${error.message}, error: ${JSON.stringify(error)}`,
						);
					}
				} else {
					self.actionName = error.response?.config?.url ?? error.config?.url;

					if (error.message === 'Network Error' || error.code === 'ECONNABORTED') {
						self.logger.trackWarning(
							`Invalid response ${self.actionName} failed for client: ${self.clientId}, errorMessage: ${error.message}`,
						);
					} else {
						self.logger.trackError(
							`Invalid response ${self.actionName} failed for client: ${self.clientId}, errorMessage: ${
								error.message
							}, error: ${JSON.stringify(error)}`,
						);
					}
				}
				return Promise.reject(error);
			},
		);
	}

	set(baseUri: string, pageSize: number) {
		this._baseUri = baseUri;
		//this._pageSize = pageSize;
	}

	getPaged<T>(page: number, uriPart?: string | undefined): any {
		return axios.get<T>(this._baseUri + uriPart);
	}

	get<T>(uriPart?: string | undefined, data?: any): any {
		return axios.get<T>(this._baseUri + uriPart, data);
	}

	post<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean): any {
		return axios.post<T>(this._baseUri + uriPart, data, config);
	}

	postJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined): any {
		return axios.post<T>(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json;utf-8' } });
	}

	put<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean): any {
		return axios.put<T>(this._baseUri + uriPart, data, config);
	}
	putJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined): any {
		return axios.put<T>(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json;utf-8' } });
	}

	delete(id: number, uriPart?: string | undefined): any {
		return axios.delete(this._baseUri + uriPart);
	}

	deleteExtended(data: any, uriPart?: any): any {
		throw new Error('Method not implemented.');
	}
}
export function initializeAxios(clientId?: string): AxiosDataService {
	return new AxiosDataService(clientId);
}

export function storeTokenInMemory(clientId: string, token: string) {
	inMemoryToken.set(clientId, token);
}
