import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { DangerIcon, SuccessIcon, ToasterCloseIcon, WarningIcon } from '../../../assets/images/SVGIcons';

export class PhobosNotifier extends React.Component<any, any> {
	static Error = (message: string) =>
		toast.error(
			<div className='toaster-icon-message'>
				<DangerIcon />
				<div
					style={{
						marginLeft: `${message.trim().length > 37 ? '14px' : '8px'}`,
					}}
					className='toaster-message'>
					{message}
				</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-error',
				closeButton: <ToasterCloseIcon />,
			},
		);

	static Warning = (message: string) =>
		toast.warn(
			<div className='toaster-icon-message'>
				<WarningIcon />
				<div
					style={{
						marginLeft: `${message.trim().length > 37 ? '14px' : '8px'}`,
					}}
					className='toaster-message'>
					{message}
				</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-warning',
				closeButton: <ToasterCloseIcon />,
			},
		);

	static Success = (message: string) =>
		toast.success(
			<div className='toaster-icon-message'>
				<SuccessIcon />
				<div
					style={{
						marginLeft: `${message.trim().length > 37 ? '14px' : '8px'}`,
					}}
					className='toaster-message'>
					{message}
				</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-success',
				closeButton: <ToasterCloseIcon />,
			},
		);

	static Info = (message: string) =>
		toast.info(
			<div className='toaster-icon-message'>
				<div
					style={{
						marginLeft: `${message.trim().length > 37 ? '14px' : '8px'}`,
					}}
					className='toaster-message'>
					{message}
				</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-info',
				closeButton: <ToasterCloseIcon />,
			},
		);
}

export class Toaster extends React.Component<any, any> {
	render() {
		return (
			<div className='toaster_container'>
				<ToastContainer
					autoClose={5000}
					hideProgressBar={true}
					newestOnTop={true}
					closeOnClick={true}
					rtl={false}
					pauseOnHover
					className='toaster'
					position='top-right'
				/>
			</div>
		);
	}
}
