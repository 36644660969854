import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { OTPVerify } from '../../components/Common/Constants';
import { TelemetryLogger } from '../../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface IOTPState {
	mfaOTPLength: number;
	validOTP: boolean;
}

interface ReceiveMFALengthAction {
	type: actionTypes.MFA_LENGTH;
	mfaOTPLength: number;
}

interface ValidateOTPAction {
	type: actionTypes.OTP_VALIDATE;
	validOTP: boolean;
}
const unloadedState: IOTPState = {
	validOTP: false,
	mfaOTPLength: 0,
} as IOTPState;

type KnownAction = ReceiveMFALengthAction | ValidateOTPAction | NotificationAction;

export const actionCreators = {
	requestMFAOTPLength:
		(id: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(id)
				.get<any>('/api/OTP/GetMFAOTPLength/' + id)
				.then(function (response: any) {
					dispatch({
						type: actionTypes.MFA_LENGTH,
						mfaOTPLength: response.data,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? 'Error occurred while requesting MFA length',
						statusType: StatusType.Error,
					});
					logger.trackWarning(`k1 shareholder requestMFAOTPLength failed with error ${error.message}`);
				});
		},

	sendOTP:
		(id: string, mobileNumber: string, countryCode: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(id)
				.post('/api/OTP/SendOTP/' + mobileNumber + '/' + countryCode + '/' + id)
				.then(function () {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPVerify.sendOTP,
						statusType: StatusType.Success,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(`k1 shareholder sendOTP failed with error ${error.message}`);
				});
		},

	validateOTP:
		(id: string, otp: string, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(id)
				.put<any>('/api/OTP/ValidateOTP/' + otp + '/' + id)
				.then(function (response: any) {
					dispatch({
						type: actionTypes.OTP_VALIDATE,
						validOTP: response.data,
					});
					callback();
					if (response.data) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPVerify.validOTP,
							statusType: StatusType.Success,
						});
					} else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPVerify.InvalidOTP,
							statusType: StatusType.Warning,
						});
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? 'Error occurred while validating access code',
						statusType: StatusType.Error,
					});
					logger.trackWarning(`k1 shareholder validateOTP failed with error ${error.message}`);
				});
		},
};
export const reducer: Reducer<IOTPState> = (state: IOTPState = unloadedState, incomingAction: Action) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.MFA_LENGTH:
			return {
				...state,
				mfaOTPLength: action.mfaOTPLength,
			} as IOTPState;
		case actionTypes.OTP_VALIDATE:
			let received: IOTPState = { ...state };
			received.validOTP = action.validOTP;
			return {
				...received,
			} as IOTPState;
		default:
			return state || unloadedState;
	}
};
