export enum actionTypes {
	NOTIFICATION,

	HEADERINFO_REQUEST,
	HEADERINFO_RESPONSE,
	HEADERINFO_FAILURE,

	K1INSTRUCTIONDETAIL_REQUEST,
	K1INSTRUCTIONDETAIL_RESPONSE,

	DOCUMENTTAXYEAR_REQUEST,
	DOCUMENTTAXYEAR_RESPONSE,

	UPDATEK1CONSENT_REQUEST,
	UPDATEK1CONSENT_RESPONSE,

	K1DETAILS_REQUEST,
	K1DETAILS_RESPONSE,

	DOWNLOADK1PACKAGE_REQUEST,
	DOWNLOADK1INSTRUCTION_REQUEST,

	SHAREHOLDER_DETAIL_REQUEST,
	SHAREHOLDER_DETAIL_RESPONSE,
	SHAREHOLDER_DETAIL_FAILURE,

	SHAREHOLDER_UPDATE_REQUEST,
	SHAREHOLDER_UPDATE_RESPONSE,
	SHAREHOLDER_UPDATE_FAILURE,

	WELCOMEMESSAGE_REQUEST,
	WELCOMEMESSAGE_RESPONSE,
	WELCOMEMESSAGE_FAILURE,

	OTP_RECEIVE,
	OTP_VALIDATE,
	MFA_LENGTH,

	DOWNLOAD_PACKAGE_LOADER,
	DOWNLOAD_INSTRUCTION_LOADER,
	K1_CONSENT_STATUS,
	K1ATTACHMENTDETAILS_RESPONSE,

	RECEIVE_HEADER_DETAILS,
	SET_MOBILE_NO,
	START_FETCH_INFO,
	STOP_OTP_LOADER,

	SET_ERROR_MSG,
}
