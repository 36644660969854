import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import TaxIdentificationNumber from '../Common/TaxIdentificationNumber';
import { SelectComponent } from '../Common/SelectComponent';
import { GetEntityType } from '../Common/helper/HelperFunctions';
import PhoneInput from 'react-phone-input-2';
import { EntityType, IShareholderInfoModel } from '../../core/domain/viewModels/IShareholderInfoModel';
import { PhobosNotifier } from '../Common/Notification/PhobosNotifier';
import { OTPVerify, ValidationContants } from '../Common/Constants';
import { isValidEmailAddress, isValidTenDigitNumber } from '../Common/Validations';

const MyAccountPopUp = (props: any) => {
	const [shareHolderInfo, setShareHolderInfo] = useState(props.shareHolderInfo);
	const [existingEmail, setExistingEmail] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [otpValue, setOtpValue] = useState('');
	const [isEmailChange, setIsEmailChange] = useState(false);
	const [disableVerifyLink, setDisableVerifyLink] = useState(false);
	const [isMobileVerify, setIsMobileVerify] = useState(false);
	const [verifyLinkClicked, setVerifyLinkClicked] = useState(false);
	const [showOtpVerification, setShowOtpVerification] = useState(false);

	useEffect(() => {
		const shareHolderInfoTemp: IShareholderInfoModel = JSON.parse(JSON.stringify(props.shareHolderInfo));
		shareHolderInfoTemp.countryCode =
			props.shareHolderInfo.countryCode === '' ||
			props.shareHolderInfo.countryCode === undefined ||
			props.shareHolderInfo.countryCode === null
				? '+1'
				: props.shareHolderInfo.countryCode;
		setShareHolderInfo(shareHolderInfoTemp);
		setExistingEmail(props.shareHolderInfo.email);
	}, [props.shareHolderInfo]);

	useEffect(() => {
		if (props.otpState.mfaOTPLength === otpValue.length) {
			if (countryCode === shareHolderInfo.countryCode && mobileNumber === shareHolderInfo.mobileNumber) {
				props.validateOTP(props.params.clientId, otpValue, hideVerifyLink);
			} else {
				PhobosNotifier.Warning(OTPVerify.InvalidOTP);
			}
		}
	}, [otpValue]);

	useEffect(() => {
		if (props.otpState.validOTP) {
			setIsMobileVerify(true);
			setShowOtpVerification(false);
		}
	}, [props.otpState.validOTP]);

	const onAddressChange = (e: any) => {
		let shareHolderInfoTemp: IShareholderInfoModel = {
			...shareHolderInfo,
		};
		shareHolderInfoTemp.address = e.target.value;
		setShareHolderInfo(shareHolderInfoTemp);
	};

	const onEmailChange = (e: any) => {
		let shareHolderInfoTemp: IShareholderInfoModel = {
			...shareHolderInfo,
		};
		shareHolderInfoTemp.email = e.target.value;
		let emailChange = existingEmail === e.target.value ? false : true;
		setShareHolderInfo(shareHolderInfoTemp);
		setIsEmailChange(emailChange);
	};

	const onEntityChange = (selectedValue: any) => {
		let shareHolderInfoTemp: IShareholderInfoModel = {
			...shareHolderInfo,
		};
		shareHolderInfoTemp.entity = parseInt(selectedValue);
		setShareHolderInfo(shareHolderInfoTemp);
	};

	const onCountryCodeChange = (countryCode: any) => {
		let shareHolderInfoTemp: IShareholderInfoModel = {
			...shareHolderInfo,
		};
		shareHolderInfoTemp.countryCode = `+${countryCode}`;
		setShareHolderInfo(shareHolderInfoTemp);
		setDisableVerifyLink(false);
		setIsMobileVerify(false);
		setVerifyLinkClicked(false);
		setShowOtpVerification(false);
	};

	const onMobileChange = (e: any) => {
		if (isValidTenDigitNumber(e.target.value)) {
			let shareHolderInfoTemp: IShareholderInfoModel = {
				...shareHolderInfo,
			};
			shareHolderInfoTemp.mobileNumber = e.target.value;
			setShareHolderInfo(shareHolderInfoTemp);
			setDisableVerifyLink(false);
			setIsMobileVerify(false);
			setVerifyLinkClicked(false);
			setShowOtpVerification(false);
		}
	};

	const isValidSSN = (ssn: string) => {
		if (ssn.replace(/-/g, '').length == 9) {
			return true;
		}
	};

	const isIndividualEntityType = (): boolean => {
		var isIndividual: boolean = false;
		if (isValidSSN(shareHolderInfo.pin ? shareHolderInfo.pin : '')) {
			if (shareHolderInfo.entity !== EntityType.None) {
				isIndividual = shareHolderInfo.entity === EntityType.Individual ? true : false;
			} else {
				isIndividual = true;
			}
		}
		return isIndividual;
	};

	const onClickVerifyOTP = () => {
		if (validateMobileNumber()) {
			let mobileNumberTemp = shareHolderInfo.mobileNumber;
			let countryCodeTemp = shareHolderInfo.countryCode;

			setShowOtpVerification(true);
			setVerifyLinkClicked(true);
			setMobileNumber(mobileNumberTemp);
			setCountryCode(countryCodeTemp);
			setOtpValue('');
			props.sendOTP(props.params.clientId, countryCodeTemp + mobileNumberTemp, countryCodeTemp);
		}
	};

	const validateMobileNumber = () => {
		if (
			shareHolderInfo.mobileNumber.trim() === '' ||
			shareHolderInfo.mobileNumber === undefined ||
			shareHolderInfo.mobileNumber === null
		) {
			PhobosNotifier.Warning(ValidationContants.ValidMobilenumber);
			return false;
		}
		if (shareHolderInfo.mobileNumber.length < 10) {
			PhobosNotifier.Warning(ValidationContants.PhoneNumberLengthWarning);
			return false;
		} else if (
			shareHolderInfo.countryCode === '' ||
			shareHolderInfo.countryCode === undefined ||
			shareHolderInfo.countryCode === null
		) {
			PhobosNotifier.Warning(ValidationContants.ValidCountryCode);
			return false;
		} else {
			return true;
		}
	};

	const onChangeOtpValue = (event: any) => {
		let otp = event.target.value;
		setOtpValue(otp);
	};

	const hideVerifyLink = () => {
		if (props.otpState.validOTP) {
			setIsMobileVerify(true);
			setShowOtpVerification(false);
		}
	};

	const validateForm = () => {
		if (shareHolderInfo.pin.trim() === '' || shareHolderInfo.pin.length < 9) {
			PhobosNotifier.Warning(ValidationContants.ValidSSN);
			return false;
		}
		if (shareHolderInfo.name.trim() === '') {
			PhobosNotifier.Warning(ValidationContants.ValidName);
			return false;
		}
		if (shareHolderInfo.address.trim() === '') {
			PhobosNotifier.Warning(ValidationContants.ValidAddress);
			return false;
		}
		if (shareHolderInfo.email.trim() === '' || !isValidEmailAddress(shareHolderInfo.email.trim())) {
			PhobosNotifier.Warning(ValidationContants.ValidEmailaddress);
			return false;
		}
		if (shareHolderInfo.mobileNumber.trim() !== '' && shareHolderInfo.mobileNumber.length < 10) {
			PhobosNotifier.Warning(ValidationContants.ValidMobilenumber);
			return false;
		}
		if (
			shareHolderInfo.mobileNumber.trim() !== '' &&
			(shareHolderInfo.countryCode === null ||
				shareHolderInfo.countryCode === undefined ||
				shareHolderInfo.countryCode === '')
		) {
			PhobosNotifier.Warning(ValidationContants.ValidCountryCode);
			return false;
		}
		if (
			shareHolderInfo.countryCode !== null &&
			shareHolderInfo.countryCode !== undefined &&
			shareHolderInfo.countryCode !== '' &&
			shareHolderInfo.mobileNumber === ''
		) {
			PhobosNotifier.Warning(ValidationContants.ValidMobilenumber);
			return false;
		}
		if (shareHolderInfo.entity === EntityType.None) {
			PhobosNotifier.Warning(ValidationContants.ValidEntity);
			return false;
		}
		return true;
	};

	const handleFormSubmit = () => {
		if (validateForm()) {
			props.updateUserInfo(shareHolderInfo, isEmailChange, isMobileVerify);
		}
	};

	return (
		<React.Fragment>
			<Modal.Body>
				<div className='k1InformationHeader'>K1 Information</div>
				<Row>
					<Col sm='12'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							id='txtk1Name'
							value={props.shareHolderInfo.name}
							disabled={true}
							data-test-auto='B8C59E79-BA98-48EF-BAA2-0DE8219468FF'
						/>
					</Col>
				</Row>
				<Row>
					<Col sm='6'>
						<Form.Label> SSN / EIN </Form.Label>
						<TaxIdentificationNumber
							id='txtk1SsnEin'
							mask={isIndividualEntityType() ? '000-00-0000' : '00-0000000'}
							placeholder=''
							value={props.shareHolderInfo.pin}
							disabled={true}
							data-test-auto='2987EFC1-1ECD-4B87-9CED-19B73E0F965A'
						/>
					</Col>
					<Col
						sm='6'
						className='k1PartnerTypeColumn'>
						<Form.Label>Partner Type</Form.Label>
						<div data-test-auto='118C9627-2B5D-480F-9269-EC46B9027BC8'>
							<SelectComponent
								id='drpEntityType'
								options={GetEntityType()}
								onChange={onEntityChange}
								selectedValue={shareHolderInfo.entity}
								disabled={props.isSaveDisabled}
								testAttribute='AC24831C-E786-443C-8291-7DF7DFBDB546'
								className='k1AccountPartnerType'
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm='12'>
						<Form.Group
							controlId='txtK1Address'
							className='groupFormElementAddress'>
							<Form.Label>Address</Form.Label>
							<Form.Control
								id='txtK1Address'
								as='textarea'
								value={shareHolderInfo.address}
								onChange={onAddressChange}
								disabled={props.isSaveDisabled}
								data-test-auto='BC36FF22-B914-4B09-9A24-EAD42896BF80'
								className={props.isSaveDisabled ? 'disabledFormElement' : ''}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col sm='12'>
						<Form.Group
							controlId='txtK1Email'
							className='groupFormElementEmailK1'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								id='txtK1Email'
								value={shareHolderInfo.email}
								onChange={onEmailChange}
								disabled={props.isSaveDisabled}
								data-test-auto='D3B65A30-9438-41F6-95EA-56350195516E'
								className={props.isSaveDisabled ? 'disabledFormElement' : ''}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						className='padTop10'
						style={{ display: 'flex', alignItems: 'flex-end' }}>
						<div className='mobileNumberSectionK1Account'>
							<label htmlFor='mobileNumberInput'>Mobile</label>
							<div
								className={`countryCodeMobileNumberWrapper ${
									props.isSaveDisabled ? 'countryCodeMobileNumberWrapperDisabled' : ''
								}`}>
								<PhoneInput
									containerClass='phoneInput'
									country={'us'}
									autoFormat={false}
									placeholder=''
									value={shareHolderInfo.countryCode}
									onChange={onCountryCodeChange}
									disabled={props.isSaveDisabled}
									disableCountryCode={props.isSaveDisabled}
								/>
								<input
									type='text'
									name='mobileNumberInput'
									id='mobileNumberInput'
									value={shareHolderInfo.mobileNumber}
									disabled={props.isSaveDisabled}
									onChange={onMobileChange}
									className='mobileNumberInput'
									maxLength={10}
								/>
							</div>
						</div>
					</Col>

					{isMobileVerify || verifyLinkClicked ? null : (
						<Col
							sm='2'
							className='padTop10'>
							<Form.Label> </Form.Label>
							<div className='verifyLinkWrapperDiv'>
								<a
									className={
										disableVerifyLink
											? 'verifyMobileNumberLink disableAnchor padTop10'
											: 'verifyMobileNumberLink enableAnchor padTop10'
									}
									href='javascript:void(0)'
									data-test-auto='75ADAEFD-EBC8-431B-941E-4BCDBAB82B9E'
									onClick={onClickVerifyOTP}
									hidden={props.isSaveDisabled}>
									Verify
								</a>
							</div>
						</Col>
					)}

					{showOtpVerification ? (
						<Col
							sm='4'
							className='padTop10'
							style={{ display: 'flex', alignItems: 'flex-end' }}>
							<Form.Group
								controlId='txtK1AccessCode'
								className='groupFormElementAccessCodeK1'>
								<Form.Label>Access Code</Form.Label>
								<input
									type='text'
									id='txtK1AccessCode'
									className='form-control'
									value={otpValue}
									onChange={onChangeOtpValue}
									data-test-auto='5E794F51-B0E5-4780-80C5-817296F78AB0'
									maxLength={props.maxLength}
								/>
							</Form.Group>
						</Col>
					) : null}
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button
					id='btnCancel'
					variant='primary'
					className='cancelMyAccountK1Button margin-top-10'
					type='button'
					onClick={props.onCancelClick}
					data-test-auto='D287CE1A-4D48-43D3-B1FA-AE66DD259580'>
					Cancel
				</Button>
				<Button
					id='btnSaveK1'
					variant='primary'
					className='saveMyAccountK1Button margin-right-10 margin-top-10'
					type='button'
					onClick={handleFormSubmit}
					hidden={props.isSaveDisabled || props.isSaveClicked}
					data-test-auto='63AE08CE-91D4-4CEB-9DE9-A263EB478379'>
					Save
				</Button>
			</Modal.Footer>
		</React.Fragment>
	);
};

export default MyAccountPopUp;
