import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
import { actionCreators as K1DownloadActionCreator } from './../../store/K1Download/K1DownloadStore';
import { bindActionCreators } from 'redux';
import { ShareHolderFlow } from './ShareHolderFlow';

function mapStateToProps(state: ApplicationState) {
	return {
		headInfoViewModel: state.k1ViewModel.headerInfo,
		k1Download: state.k1Download,
		taxYear: state.k1ViewModel.headerInfo?.taxYear,
		k1Name: state.k1ViewModel.headerInfo?.clientName,
		isK1Declained: state.k1ViewModel.shareHolderInfo?.isK1Decline,
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		...bindActionCreators({ ...K1DownloadActionCreator }, dispatch),
	};
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ShareHolderFlow);
