import { Action } from 'history';
import { actionTypes } from './../../../store/ActionTypes';

interface K1IntructionFileNameResponseAction {
	type: actionTypes.K1INSTRUCTIONDETAIL_RESPONSE;
	fileName: string;
}

export type DispatchAction =
	| K1IntructionFileNameResponseAction
	| DownloadPackageLoaderAction
	| DownloadInstructionLoaderAction
	| K1ConsentStatusAction
	| GetK1AttachementDetailsAction;

export interface K1DownloadStoreState {
	InstructonFileName: string;
	packageLoader: boolean;
	instructionLoader: boolean;
	consentStatus: ConsentStatus;
	k1Attachments: K1AttachmentInfoModel[];
}

export interface K1AttachmentInfoModel {
	filePath: string;
	fileName: string;
}

export enum ConsentStatus {
	None,
	Declined,
	Agreed,
}

export const initialState: K1DownloadStoreState = {
	InstructonFileName: '',
	packageLoader: false,
	instructionLoader: false,
	consentStatus: ConsentStatus.None,
	k1Attachments: [],
};

interface DownloadPackageLoaderAction {
	type: actionTypes.DOWNLOAD_PACKAGE_LOADER;
	packageLoader: boolean;
}

interface DownloadInstructionLoaderAction {
	type: actionTypes.DOWNLOAD_INSTRUCTION_LOADER;
	instructionLoader: boolean;
}

interface K1ConsentStatusAction {
	type: actionTypes.K1_CONSENT_STATUS;
	consentStatus: ConsentStatus;
}

interface GetK1AttachementDetailsAction {
	type: actionTypes.K1ATTACHMENTDETAILS_RESPONSE;
	k1Attachments: K1AttachmentInfoModel[];
}
