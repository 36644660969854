import { interfaces } from 'inversify';

export interface IWelcomeMessageModal {
	id: number;
	name: string;
	text: string;
	header: string;
}

export const initialWelcomeMessageModal: IWelcomeMessageModal = {
	id: 0,
	header: '',
	name: '',
	text: '',
};
