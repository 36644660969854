import { Container } from 'inversify';
import 'reflect-metadata';
import { UtilityFactory } from '../core/utilities/factory/UtilityFactory';
import { FileUtilities, IFileUtilities } from '../core/utilities/File/FileUtilities';
import { IHtmlUtilities } from '../core/utilities/HtmlUtilities';
import { ILocalStore } from '../core/utilities/LocalStore';
import { IRegexUtilities, RegexUtilities } from '../core/utilities/RegexUtilities';
import { ITextUtilities } from '../core/utilities/TextUtilities';
import { IDialogBox } from '../core/utilities/ui/DialogBox';
import { IUtilities, Utilities } from '../core/utilities/Utilities';
import { IWindowUtilities } from '../core/utilities/WindowUtilities';
import { IJsonUtilities, JsonUtilities } from '../core/utilities/JSON/JsonUtilities';
import { TYPES } from './types';
import { IDataService } from '../core/services/dataAccess/abstraction/IDataService';
import { Factory } from '../startup/Factory';

const container = new Container();

container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<IDataService>(TYPES.IDataService).toConstantValue(Factory.createDataService());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container
	.bind<IWindowUtilities>(TYPES.IWindowUtilities)
	.toConstantValue(new UtilityFactory().createWindowUtilityService());
container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<IRegexUtilities>(TYPES.IRegexUtilities).to(RegexUtilities);
container.bind<IUtilities>(TYPES.IUtilities).to(Utilities);
container.bind<IFileUtilities>(TYPES.IFileUtilities).to(FileUtilities);
container.bind<IJsonUtilities>(TYPES.IJsonUtilities).to(JsonUtilities);

export { container };
