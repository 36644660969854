import React from 'react';

const Logout = () => {
	return (
		<>
			<div className='upperLogoutContainer'>
				<svg
					width='103'
					height='96'
					viewBox='0 0 103 96'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<g clipPath='url(#clip0_7262_13545)'>
						<path
							d='M9.59998 48C9.59998 54.0408 10.7898 60.0225 13.1015 65.6034C15.4132 71.1844 18.8016 76.2554 23.0731 80.5269C27.3446 84.7984 32.4156 88.1867 37.9965 90.4985C43.5775 92.8102 49.5592 94 55.6 94C61.6408 94 67.6224 92.8102 73.2034 90.4985C78.7844 88.1867 83.8554 84.7984 88.1269 80.5269C92.3984 76.2554 95.7867 71.1844 98.0984 65.6034C100.41 60.0225 101.6 54.0408 101.6 48C101.6 41.9592 100.41 35.9775 98.0984 30.3966C95.7867 24.8156 92.3984 19.7446 88.1269 15.4731C83.8554 11.2016 78.7844 7.81326 73.2034 5.50154C67.6224 3.18982 61.6408 2 55.6 2C49.5592 2 43.5775 3.18982 37.9965 5.50154C32.4156 7.81326 27.3446 11.2016 23.0731 15.4731C18.8016 19.7446 15.4132 24.8156 13.1015 30.3966C10.7898 35.9775 9.59998 41.9592 9.59998 48Z'
							fill='#C2D4B3'
							stroke='#C2D4B3'
							strokeWidth='2.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M74.3109 31.9532L44.1754 62.6623C43.7959 63.0501 43.3416 63.3565 42.84 63.5632C42.3383 63.7698 41.7999 63.8722 41.2575 63.8641C40.7224 63.8566 40.1943 63.7419 39.7044 63.5266C39.2145 63.3113 38.7727 62.9999 38.4053 62.6108L23.6951 46.9881'
							stroke='#5C853A'
							strokeWidth='2.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M2 48.3999C2 54.4407 3.18982 60.4224 5.50154 66.0033C7.81326 71.5843 11.2016 76.6553 15.4731 80.9268C19.7446 85.1983 24.8156 88.5866 30.3966 90.8984C35.9775 93.2101 41.9592 94.3999 48 94.3999C54.0408 94.3999 60.0225 93.2101 65.6034 90.8984C71.1844 88.5866 76.2554 85.1983 80.5269 80.9268C84.7984 76.6553 88.1867 71.5843 90.4985 66.0033C92.8102 60.4224 94 54.4407 94 48.3999C94 42.3591 92.8102 36.3774 90.4985 30.7965C88.1867 25.2155 84.7984 20.1445 80.5269 15.873C76.2554 11.6015 71.1844 8.21316 65.6034 5.90144C60.0225 3.58973 54.0408 2.3999 48 2.3999C41.9592 2.3999 35.9775 3.58973 30.3966 5.90144C24.8156 8.21316 19.7446 11.6015 15.4731 15.873C11.2016 20.1445 7.81326 25.2155 5.50154 30.7965C3.18982 36.3774 2 42.3591 2 48.3999Z'
							stroke='#5C853A'
							strokeWidth='2.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</g>
					<defs>
						<clipPath id='clip0_7262_13545'>
							<rect
								width='103'
								height='96'
								fill='white'
							/>
						</clipPath>
					</defs>
				</svg>

				<div className='logoutMessage1'>You've successfully logged off</div>
				<div className='logoutMessage2'>For security purposes, we recommend closing your browser window.</div>
			</div>
			<div className='logoutFooterContainer'>
				<div>Copyright © 2024 cPaperless LLC</div>
				<div>
					<a
						href='https://safesend.com/legal-policies/terms-of-use.php'
						target='_blank'
						rel='noreferrer'
						className='logoutFooterLinks'>
						Terms of service
					</a>{' '}
					-{' '}
					<a
						href='https://safesend.com/legal-policies/privacy-statement.php'
						target='_blank'
						rel='noreferrer'
						className='logoutFooterLinks'>
						Privacy Policy
					</a>
				</div>
			</div>
		</>
	);
};

export default Logout;
