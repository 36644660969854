import React from 'react';

const PaperClip: React.FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4.5 3C4.5 1.61929 5.61929 0.5 7 0.5C8.38071 0.5 9.5 1.61929 9.5 3V12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12V5C6.5 4.72386 6.72386 4.5 7 4.5C7.27614 4.5 7.5 4.72386 7.5 5V12C7.5 12.2761 7.72386 12.5 8 12.5C8.27614 12.5 8.5 12.2761 8.5 12V3C8.5 2.17157 7.82843 1.5 7 1.5C6.17157 1.5 5.5 2.17157 5.5 3V12C5.5 13.3807 6.61929 14.5 8 14.5C9.38071 14.5 10.5 13.3807 10.5 12V5C10.5 4.72386 10.7239 4.5 11 4.5C11.2761 4.5 11.5 4.72386 11.5 5V12C11.5 13.933 9.933 15.5 8 15.5C6.067 15.5 4.5 13.933 4.5 12V3Z'
				fill='#05386B'
			/>
		</svg>
	);
};

export default PaperClip;
