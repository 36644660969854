import { LocalStorageStore, ILocalStore } from '../LocalStore';
import { TextUtilities, ITextUtilities } from '../TextUtilities';
import { WindowUtilities, IWindowUtilities } from '../WindowUtilities';
import { HtmlUtilities, IHtmlUtilities } from '../HtmlUtilities';
import { DialogBox, IDialogBox } from '../ui/DialogBox';

export class UtilityFactory {
	public createLocalStore(): ILocalStore {
		return new LocalStorageStore();
	}

	public createTextUtilityService(): ITextUtilities {
		return new TextUtilities();
	}

	public createWindowUtilityService(): IWindowUtilities {
		return new WindowUtilities();
	}

	public createHtmlUtilities(): IHtmlUtilities {
		return new HtmlUtilities();
	}

	public createDialogBox(): IDialogBox {
		return new DialogBox();
	}
}
