import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { IShareholderInfoModel, initialShareHolderInfoVModel } from '../core/domain/viewModels/IShareholderInfoModel';
import { TelemetryLogger } from '../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestShareholderDetails {
	type: actionTypes.SHAREHOLDER_DETAIL_REQUEST;
	clientId: string;
}
interface ResponseShareholderDetails {
	type: actionTypes.SHAREHOLDER_DETAIL_RESPONSE;
	shareHolderInfo: IShareholderInfoModel;
}
interface FailureShareholderInfo {
	type: actionTypes.SHAREHOLDER_DETAIL_FAILURE;
	id: string;
}

interface ResponseShareholderUpdate {
	type: actionTypes.SHAREHOLDER_UPDATE_RESPONSE;
	shareHolderInfo: IShareholderInfoModel;
}
interface FailureShareholderUpdate {
	type: actionTypes.SHAREHOLDER_UPDATE_FAILURE;
	id: string;
}

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction =
	| RequestShareholderDetails
	| ResponseShareholderDetails
	| FailureShareholderInfo
	| ResponseShareholderUpdate
	| FailureShareholderUpdate;

export const actionCreators = {
	requestUserInfo:
		(id: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let state = getState();
			//dispatch({ type: actionTypes.SHAREHOLDER_DETAIL_REQUEST, clientId: id });
			return initializeAxios()
				.get<IShareholderInfoModel>('/api/K1User/GetUserDetail/' + id)
				.then(function (response: AxiosResponse<IShareholderInfoModel>) {
					dispatch({
						type: actionTypes.SHAREHOLDER_DETAIL_RESPONSE,
						shareHolderInfo: response.data,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					dispatch({ type: actionTypes.SHAREHOLDER_DETAIL_FAILURE, id: id });
					logger.trackWarning(`k1 shareholder requestUserInfo failed with error ${error.message}`);
				});
		},
	updateUserInfo:
		(
			id: string,
			shareholder: IShareholderInfoModel,
			isEmailChange: boolean,
			callback?: any,
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let state = getState();
			const options = {
				headers: {
					Accept: 'application/json, text/plain, *',
					'Content-Type': 'application/json; charset=utf-8',
				},
			};
			//dispatch({ type: actionTypes.SHAREHOLDER_DETAIL_REQUEST, clientId: id });
			return initializeAxios()
				.postJson(shareholder, '/api/K1User/UpdateUserDetail/' + isEmailChange + '/' + id)
				.then(function () {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: 'Profile updated succesfully',
						statusType: StatusType.Success,
					});
					dispatch({
						type: actionTypes.SHAREHOLDER_DETAIL_RESPONSE,
						shareHolderInfo: shareholder,
					});
					if (callback) {
						callback();
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					dispatch({ type: actionTypes.SHAREHOLDER_DETAIL_FAILURE, id: id });
					logger.trackWarning(`k1 shareholder updateUserInfo failed with error ${error.message}`);
				});
		},
};

export const reducer: Reducer<IShareholderInfoModel> = (
	state: IShareholderInfoModel = initialShareHolderInfoVModel,
	incomingAction: Action,
) => {
	const action = incomingAction as DispatchAction;
	var data = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.SHAREHOLDER_DETAIL_REQUEST:
			return initialShareHolderInfoVModel;
		case actionTypes.SHAREHOLDER_DETAIL_RESPONSE:
			return action.shareHolderInfo;
		default:
			return state || initialShareHolderInfoVModel;
	}
};
