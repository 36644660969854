import { injectable } from 'inversify';

export interface IFileUtilities {
	getExtension(fileName: string): string;
	isValidateSize(file: any, maxSize: number): boolean;
	isValidateExtension(file: any, expectedExtension: string): boolean;
}

@injectable()
export class FileUtilities implements IFileUtilities {
	getExtension(fileName: string): string {
		return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
	}

	isValidateSize(file: any, maxSize: number): boolean {
		return file.size <= maxSize;
	}

	isValidateExtension(file: any, expectedExtension: string): boolean {
		const fileExtension: string = this.getExtension(file);
		return fileExtension.toLowerCase() === expectedExtension;
	}
}
