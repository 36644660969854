import React from 'react';
export interface SvgIconProps {
	fillColor?: string;
}
const DownArrow: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			width='15'
			height='15'
			viewBox='0 0 15 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.54354 4.35604C1.7266 4.17299 2.0234 4.17299 2.20646 4.35604L7.5 9.64959L12.7935 4.35604C12.9766 4.17299 13.2734 4.17299 13.4565 4.35604C13.6395 4.5391 13.6395 4.8359 13.4565 5.01896L7.83146 10.644C7.6484 10.827 7.3516 10.827 7.16854 10.644L1.54354 5.01896C1.36049 4.8359 1.36049 4.5391 1.54354 4.35604Z'
				fill={props.fillColor || '#FFFFFF'}
			/>
		</svg>
	);
};

export default DownArrow;
