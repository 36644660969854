import React, { FC, ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import ModalCloseIcon from '../../../assets/images/ModalCloseIcon';

interface SuiteModalProps {
	show: boolean;
	width: string;
	title: string;
	theme: string;
	hideCloseIcon?: boolean;
	onClickOutside: () => void;
	onClickClose?: () => void;
	children: ReactElement<any, any>;
	className?: string;
}
const SuiteModal: FC<SuiteModalProps> = (props) => {
	const { show, width, title, theme, onClickOutside, onClickClose, children, className, hideCloseIcon } = props;
	let widthClassName = `modal${width}`;
	return (
		<Modal
			show={show}
			onHide={onClickOutside}
			className={`suiteModal ${widthClassName} ${className ? className : ''}`}>
			<Modal.Header className={theme === 'light' ? 'lighttheme' : 'darktheme'}>
				<div>
					<Modal.Title>{title}</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={onClickClose}>
					{!hideCloseIcon && <ModalCloseIcon />}
				</div>
			</Modal.Header>
			{children}
		</Modal>
	);
};

export default SuiteModal;
