import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout/Layout';
import MainComponent from './components/Home/MainComponentContainer';
import MobileOTP from './components/MobileOTP/OTPPageContainer';
import OTP from './components/OTP/OTPPageContainer';
import OTPRouteSelect from './components/RouteSelect/RouteSelectContainer';
import { initializeAppInsights, TelemetryLogger } from './Logger/AppInsights';
import ProfileOTP from './components/profile/OTP/OTPPageContainer';
import ProfileMobileOTP from './components/profile/MobileOTP/OTPPageContainer';
import ProfileOTPRouteSelect from './components/profile/RouteSelect/RouteSelectContainer';
import ErrorContainer from './components/Common/ErrorContainer';
import { InvalidLink } from './components/Common/InvalidLink';
import Logout from './components/Account/Logout';

export const logger = TelemetryLogger.getInstance();

export const routes = (
	<Switch>
		<RouteWrapper
			exact
			path='/invalid'
			component={InvalidLink}
		/>
		<RouteWrapper
			exact
			path='/k1/Downloads/:clientId'
			component={MainComponent}
			layout={Layout}
		/>

		<RouteWrapper
			exact
			path='/Error'
			component={ErrorContainer}
		/>

		<RouteWrapper
			exact
			path='/k1/download/:clientId'
			component={OTPRouteSelect}
		/>
		<RouteWrapper
			exact
			path='/K1/MobileOTP/:clientId'
			component={MobileOTP}
		/>
		<RouteWrapper
			exact
			path='/K1/OTP/:clientId'
			component={OTP}
		/>

		<RouteWrapper
			exact
			path='/k1/ClientProfile/:clientId'
			component={ProfileOTPRouteSelect}
		/>
		<RouteWrapper
			exact
			path='/K1/MobileOTP/profile/:clientId'
			component={ProfileMobileOTP}
		/>
		<RouteWrapper
			exact
			path='/K1/OTP/profile/:clientId'
			component={ProfileOTP}
		/>
		<Route
			exact
			path='/Authentication/Logout/:clientId'
			render={() => <Logout />}
		/>
		<RouteWrapper
			path='*'
			component={InvalidLink}
		/>
	</Switch>
);

function RouteWrapper({ component: Component, layout: Layout, ...rest }: any) {
	initializeAppInsights(rest.computedMatch.params.clientId);
	return (
		<Route
			{...rest}
			render={(props) =>
				Layout ? (
					<Layout {...props}>
						<Component {...props} />
					</Layout>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}
