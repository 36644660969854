import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Toaster } from '../Common/Notification/PhobosNotifier';
import Notification from '../Common/Notification/NotificationContainer';

export class RouteSelector extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			otpVal: '',
			continueState: false,
		};

		this.selectPath = this.selectPath.bind(this);
	}

	componentDidMount() {
		this.props.otpPath(this.props.match.params.clientId, this.selectPath, this.handleError);
	}

	handleError = () => {
		this.props.history.push('/Error');
	};

	selectPath(path: string) {
		let clientId: string = this.props.match.params.clientId;
		if (path === 'EmailOTP') {
			this.props.history.push(`/K1/OTP/${clientId}`);
		} else {
			this.props.history.push(`/K1/MobileOTP/${clientId}`);
		}
	}

	render() {
		return (
			<>
				<Notification />
				<Toaster />
				<Skeleton
					circle={false}
					height={'100vh'}
					width={'100vw'}
				/>{' '}
				:
			</>
		);
	}
}
export default RouteSelector;
