import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IK1ViewModel, initialK1ViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { TelemetryLogger } from '../../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestHeaderInfoAction {
	type: actionTypes.HEADERINFO_REQUEST;
	id: string;
}

interface ResponseHeaderInfoAction {
	type: actionTypes.HEADERINFO_RESPONSE;
	data: IK1ViewModel;
}
interface FailureHeaderInfoAction {
	type: actionTypes.HEADERINFO_FAILURE;
	id: string;
}

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction = ResponseHeaderInfoAction | RequestHeaderInfoAction | FailureHeaderInfoAction;

export const actionCreators = {
	requestHeaderInfo:
		(id: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.HEADERINFO_REQUEST, id: id });
			return initializeAxios(id)
				.get<IK1ViewModel>('/api/Helper/GetHeaderInfoAsync/' + id, { withCredentials: true })
				.then(function (response: AxiosResponse<IK1ViewModel>) {
					dispatch({
						type: actionTypes.HEADERINFO_RESPONSE,
						data: response.data,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					dispatch({ type: actionTypes.HEADERINFO_FAILURE, id: id });
					logger.trackWarning(
						`k1 shareholder requestHeaderInfo failed with error ${error.message} for clientId: ${id}`,
					);
				});
		},

	refreshToken:
		(clientId: string, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			return initializeAxios(clientId)
				.post('/api/K1/refresh/' + clientId)
				.then(function () {
					callback && callback();
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder refreshToken failed with error ${error.message} for clientId: ${clientId}`,
					);
				});
		},
};
export const reducer: Reducer<IK1ViewModel> = (state: IK1ViewModel = initialK1ViewModel, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.HEADERINFO_REQUEST:
			return initialK1ViewModel;
		case actionTypes.HEADERINFO_RESPONSE:
			return action.data;
		default:
			return state || initialK1ViewModel;
	}
};
