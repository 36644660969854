import React from 'react';

const Footer = () => {
	return (
		<div className='app-footer'>
			<div className='copyright-text'>Copyright © 2023 cPaperless LLC</div>
			<div className='privacy-policy'>
				<a
					href='https://safesend.com/legal-policies/terms-of-use.php'
					target='_blank'>
					Terms of service
				</a>
				<div className='hyphen'>-</div>
				<a
					href='https://safesend.com/legal-policies/privacy-statement.php'
					target='_blank'>
					Privacy Policy
				</a>
			</div>
		</div>
	);
};

export default Footer;
