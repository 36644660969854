import { IUserModel, initialUserModel } from '../models/IUserModel';
import { IBrandingSettings, initialBrandingSettings } from '../models/IBrandingSettings';
import { IShareholderInfoModel, initialShareHolderInfoVModel } from './IShareholderInfoModel';

export interface IHeaderInfoViewModel {
	clientName: string;
	taxYear: number;
	companyName: string;
	companyLogoPath: string;
	companyWhiteLogoPath: string;
	contactPerson: IUserModel;
	brandingSettings: IBrandingSettings;
	isShowProfile: boolean;
	partnershipName: string;
}

export const initialHeaderInfoViewModel: IHeaderInfoViewModel = {
	clientName: '',
	taxYear: 0,
	companyName: '',
	companyLogoPath: '',
	companyWhiteLogoPath: '',
	contactPerson: initialUserModel,
	brandingSettings: initialBrandingSettings,
	isShowProfile: false,
	partnershipName: '',
};

export interface IK1ViewModel {
	headerInfo: IHeaderInfoViewModel;
	shareHolderInfo: IShareholderInfoModel;
	sessionIdleTime: number;
}

export const initialK1ViewModel: IK1ViewModel = {
	headerInfo: initialHeaderInfoViewModel,
	shareHolderInfo: initialShareHolderInfoVModel,
	sessionIdleTime: 0,
};
