import {
	createStore,
	applyMiddleware,
	compose,
	combineReducers,
	StoreEnhancer,
	Store,
	StoreEnhancerStoreCreator,
	ReducersMapObject,
} from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { ApplicationState, reducers } from './store';
import { History, createBrowserHistory } from 'history';

export const history = createBrowserHistory();

function buildRootReducer(history: History, allReducers: ReducersMapObject) {
	return combineReducers(
		Object.assign({}, allReducers, {
			router: connectRouter(history),
		}),
	);
}

export default function configureStore(history: History, initialState: ApplicationState) {
	// Build middleware. These are functions that can process the actions before they reach the store.
	const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
	// If devTools is installed, connect to it
	const devToolsExtension = windowIfDefined && (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);
	const createStoreWithMiddleware = compose<StoreEnhancerStoreCreator<any>>(
		applyMiddleware(thunk, routerMiddleware(history)),
		devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next,
	)(createStore);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = buildRootReducer(history, reducers);
	const store = createStoreWithMiddleware(allReducers, initialState) as Store<ApplicationState>;

	return store;
}
