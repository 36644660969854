import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';

export interface ErrorState {
	message: string;
}

export interface ErrorAction {
	type: actionTypes.SET_ERROR_MSG;
	data: string;
}

type KnownAction = ErrorAction;

let initialErrorState: ErrorState = { message: '' };

export const reducer: Reducer<ErrorState> = (state: ErrorState = initialErrorState, incomingAction: Action) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.SET_ERROR_MSG:
			return {
				message: action.data,
			} as ErrorState;

		default:
			return state || initialErrorState;
	}
};
