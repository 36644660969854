import * as React from 'react';
import { ErrorState } from '../../store/Common/ErrorStore';

interface ErrorPageState {}

interface ErrorPageProps {
	ErrorMessage: ErrorState;
}

export class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
	constructor(props: ErrorPageProps, states: ErrorPageState) {
		super(props, states);
	}

	public render() {
		return (
			<>
				<div className='ErrorContainer'>
					<div className='Errorheader'></div>
					<div className='ErrorPageContent'>
						<div className='MessageContainer'>
							<p className='ErrorMsgPara'>{this.props.ErrorMessage.message}</p>
						</div>
					</div>
				</div>
			</>
		);
	}
}
