import * as NotificationStore from '../store/Common/NotificationStore';
import * as LoaderStore from '../components/Common/LoaderStore';
import { IK1ViewModel } from '../core/domain/viewModels/IHeaderInfoViewModel';
import { K1DownloadStoreState } from '../core/domain/viewModels/IK1DownloadViewModel';
import * as AccountStore from '../store/Common/AccountStore';
import { reducer as K1DownloadReducers } from '../store/K1Download/K1DownloadStore';
import * as UserInfoStore from './UserInfoStore';
import { IWelcomeMessageModal } from '../core/domain/viewModels/WelcomeMessage';
import * as WelcomeMessageStore from '../store/Common/WelcomeMessageStore';
import { IShareholderInfoModel } from '../core/domain/viewModels/IShareholderInfoModel';
import * as OtpStore from './Common/OTPStore';
import * as AuthStore from './Common/AuthStore';
import * as HeaderStore from './HeaderStore';
import * as ErrorReducer from '../store/Common/ErrorStore';

// The top-level state object
export interface ApplicationState {
	k1ViewModel: IK1ViewModel;
	notification: NotificationStore.NotificationState;
	loader: LoaderStore.IRequestStatusDictionary;
	k1Download: K1DownloadStoreState;
	welcomeMessageModal: IWelcomeMessageModal;
	shareHolderInfo: IShareholderInfoModel;
	otpState: OtpStore.IOTPState;
	AuthState: AuthStore.IOTPPage;
	HeaderState: HeaderStore.IBaseViewModel;
	ErrorState: ErrorReducer.ErrorState;
}

export const reducers = {
	k1ViewModel: AccountStore.reducer,
	notification: NotificationStore.reducer,
	loader: LoaderStore.reducer,
	k1Download: K1DownloadReducers,
	welcomeMessageModal: WelcomeMessageStore.reducer,
	shareHolderInfo: UserInfoStore.reducer,
	otpState: OtpStore.reducer,
	AuthState: AuthStore.reducer,
	HeaderState: HeaderStore.reducer,
	ErrorState: ErrorReducer.reducer,
};

export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}
