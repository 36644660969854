import { ApplicationState } from '../../store/index';
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import DefaultLayout from './DefaultLayout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionCreators as otpStore } from '../../store/Common/OTPStore';
import { actionCreators as UserInfoStore } from '../../store/UserInfoStore';
import { actionCreators as WelcomeMessageStore } from '../../store/Common/WelcomeMessageStore';
import { IShareholderInfoModel } from '../../core/domain/viewModels/IShareholderInfoModel';

function mapStateToProps(state: ApplicationState) {
	return {
		headerInfo: state.k1ViewModel.headerInfo,
		sessionIdleTime: state.k1ViewModel.sessionIdleTime,
		shareHolderInfo: state.shareHolderInfo,
		welcomeMessageModal: state.welcomeMessageModal,
		otpState: state.otpState,
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		requestHeaderInfo: (id: string) => dispatch(accountAction.requestHeaderInfo(id)),
		refreshToken: (clientId: string, callback: any) => dispatch(accountAction.refreshToken(clientId, callback)),
		requestUserInfo: (id: string) => dispatch(UserInfoStore.requestUserInfo(id)),
		updateUserInfo: (id: string, shareholder: IShareholderInfoModel, isEmailChange: boolean, callback: any) =>
			dispatch(UserInfoStore.updateUserInfo(id, shareholder, isEmailChange, callback)),
		requestWelcomeMessageInfo: (id: string) => dispatch(WelcomeMessageStore.requestWelcomeMessageInfo(id)),
		requestMFAOTPLength: (id: string) => dispatch(otpStore.requestMFAOTPLength(id)),
		sendOTP: (id: string, mobileNumber: string, countryCode: string) =>
			dispatch(otpStore.sendOTP(id, mobileNumber, countryCode)),
		validateOTP: (id: string, otp: string, callback: () => void) => dispatch(otpStore.validateOTP(id, otp, callback)),
		dispatch,
	};
};

const connector: any = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DefaultLayout));
