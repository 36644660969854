import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { DownloadFile } from './../../components/Common/DownloadFile';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	DispatchAction,
	initialState,
	K1DownloadStoreState,
	ConsentStatus,
	K1AttachmentInfoModel,
} from './../../core/domain/viewModels/IK1DownloadViewModel';
import { TelemetryLogger } from '../../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

type KnownAction = NotificationAction | DispatchAction;

export const actionCreators = {
	downloadK1Instruction:
		(clientGuid: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			dispatch({ type: actionTypes.DOWNLOAD_INSTRUCTION_LOADER, instructionLoader: true });
			let config: AxiosRequestConfig = {
				responseType: 'arraybuffer',
				headers: { 'Content-Type': 'application/json;utf-8' },
			};
			return initializeAxios(clientGuid)
				.get<any>('/api/Download/DownloadK1Instruction/' + clientGuid, config)
				.then((response: any) => {
					const contentDisposition = response.headers['content-disposition'];
					const fileNameArray: any = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
					const fileName: string = fileNameArray[1] ? fileNameArray[1] : 'Instruction.pdf';
					let File = new DownloadFile();
					File.downloadFile(response.data, fileName);
					dispatch({ type: actionTypes.DOWNLOAD_INSTRUCTION_LOADER, instructionLoader: false });
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder downloadK1Instruction failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},

	dowloadK1Package:
		(clientGuid: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			dispatch({ type: actionTypes.DOWNLOAD_PACKAGE_LOADER, packageLoader: true });
			let config: AxiosRequestConfig = {
				responseType: 'arraybuffer',
				headers: { 'Content-Type': 'application/json;utf-8' },
			};
			return initializeAxios(clientGuid)
				.get<any>('/api/Download/DownloadK1Package/' + clientGuid, config)
				.then((response: any) => {
					const contentDisposition = response.headers['content-disposition'];
					const fileNameArray: any = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
					const fileName: string = fileNameArray[1] ? fileNameArray[1] : getState().k1ViewModel.headerInfo.clientName;
					let File = new DownloadFile();
					File.downloadFile(response.data, fileName);
					dispatch({ type: actionTypes.DOWNLOAD_PACKAGE_LOADER, packageLoader: false });
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder dowloadK1Package failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},
	getInstructionName:
		(clientGuid: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(clientGuid)
				.get<string>('/api/Download/GetK1InstructionFileName/' + clientGuid)
				.then((response: AxiosResponse<string>) => {
					dispatch({ type: actionTypes.K1INSTRUCTIONDETAIL_RESPONSE, fileName: response.data });
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder getInstructionName failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},

	updateConsent:
		(clientGuid: string, consent: boolean, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(clientGuid)
				.post('/api/Download/UpdateConsent/' + consent + '/' + clientGuid)
				.then(() => {
					callback();
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder updateConsent failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},

	getK1ConsentStatus:
		(clientGuid: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(clientGuid)
				.get<any>('/api/Download/GetK1ConsentStatus/' + clientGuid)
				.then((response: AxiosResponse<ConsentStatus>) => {
					dispatch({ type: actionTypes.K1_CONSENT_STATUS, consentStatus: response.data });
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder getK1ConsentStatus failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},

	getK1AttachmentDetails:
		(clientGuid: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			return initializeAxios(clientGuid)
				.get<any>('/api/Download/GetK1AttachmentDetailsAsync/' + clientGuid)
				.then((response: AxiosResponse<K1AttachmentInfoModel[]>) => {
					dispatch({ type: actionTypes.K1ATTACHMENTDETAILS_RESPONSE, k1Attachments: response.data });
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder getK1AttachmentDetails failed with error ${error.message} for clientId: ${clientGuid}`,
					);
				});
		},
};

export const reducer: Reducer<K1DownloadStoreState> = (
	state: K1DownloadStoreState = initialState,
	incomingAction: Action,
) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.K1INSTRUCTIONDETAIL_RESPONSE:
			return {
				...state,
				InstructonFileName: action.fileName,
			};
		case actionTypes.DOWNLOAD_PACKAGE_LOADER:
			return {
				...state,
				packageLoader: action.packageLoader,
			};
		case actionTypes.DOWNLOAD_INSTRUCTION_LOADER:
			return {
				...state,
				instructionLoader: action.instructionLoader,
			};
		case actionTypes.K1_CONSENT_STATUS:
			return {
				...state,
				consentStatus: action.consentStatus,
			};
		case actionTypes.K1ATTACHMENTDETAILS_RESPONSE:
			return {
				...state,
				k1Attachments: action.k1Attachments,
			};
		default:
			return state || initialState;
	}
};
