export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const Common = {
	DialogBox: {
		ConfirmationTitle: 'Confirmation',
	},
	Images: {
		download: './dist/assets/images/icon-download-docs.png',
		SummaryStepIcon: './dist/assets/images/Taxpayer-Summary.svg',
		ReviewStepIcon: './dist/assets/images/Taxpayer-Review.svg',
		SignStepIcon: './dist/assets/images/Taxpayer-Sign.svg',
		PayStepIcon: './dist/assets/images/Taxpayer-Pay.svg',
		PdfIcon: './dist/assets/images/pdf1.png',
		downloadIcon: './dist/assets/images/icon-download.png',
	},
};
export const ValidationContants = {
	PhoneNumberWarning: 'Please enter the phone number.',
	PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
	ValidSSN: 'Please enter SSN/EIN.',
	ValidName: 'Please enter name.',
	ValidAddress: 'Please enter address.',
	ValidEmailaddress: 'Please enter valid email address',
	ValidMobilenumber: 'Please enter valid mobile number.',
	ValidEntity: 'Please select shareholder/partner type.',
	ValidCountryCode: 'Please select country code',
};

export const OTPVerify = {
	InvalidOTP: 'Invalid access code',
	validOTP: 'Access code verified successfully',
	sendOTP: 'Access code sent successfully',
};

export const OTPCheck = {
	ServerError: 'Internal Server Error, Please try again.',
};

export const HeaderConstants = {
	ErrorMessage: {
		RetrieveFailed: 'Unable to fetch Details, Please Refresh and try again',
	},
};

export const OTPPageConstants = {
	SuccessMessage: {
		OTPGenerateSuccess: 'A one-time access code was sent to your email',
	},
	ErrorMessage: {
		lockedByOTP: 'Maximum retry reached for one-time access code',
		serverError: 'Internal Server Error, Please try to refresh',
		OTPGenerateFailed: 'Unable to generate one-time access code, Please try again',
		OTPExpired: 'Entered one-time access code has Expired',
		OTPError: 'Provided one-time access code is invalid',
	},
};

export const OTPMobilePageConstants = {
	SuccessMessage: {
		OTPGenerateSuccess: 'A one-time access code was sent to you via a text message',
	},
	ErrorMessage: {
		lockedByOTP: 'Maximum retry reached for one-time access code',
		serverError: 'Internal Server Error, Please try to refresh',
		OTPGenerateFail: 'Unable to generate one-time access code, Please try again',
		clientFetchFailed: 'Unable to fetch client details, Please try again',
	},
};

export const DisplayError = [
	'ERROR_DELETED_RETURN',
	'ERROR_RECALLED_RETURN',
	'ERROR_INVALID_LINK',
	'OTP_LOCKED_MESSAGE',
	'SSN_LOCKED_MESSAGE',
	'SSN_LOCKED',
	'OTP_LOCKED',
	'RETURN_DECLINED_FOR_SIGN',
];

export const AuthToasterError = ['OTP_EXPIRED'];

export const KNOWN_EXCEPTION_MESSAGES = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'ErrorEvent:',
	'ErrorEvent: Script error.',
];
