import * as React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Countdown } from '../Helper/Countdown';
import { match } from 'react-router';
import { container } from '../../startup/inversify.config';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { TYPES } from '../../startup/types';
import SessionTimeoutSVG from 'src/assets/images/SessionTimeoutSVG';

declare global {
	interface Window {
		Variables: any;
	}
}

const WarningVisibleMinutes = 1;
const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

interface ISessionTimeoutProps {
	match: match;
	refreshToken(clientId: string, callback: () => void): void;
	sessionIdleTime: number;
}

interface ISessionTimeoutState {
	sessionIdleTime: number;
	showTimeout: boolean;
}

export class SessionTimeout extends React.Component<ISessionTimeoutProps, ISessionTimeoutState> {
	private countDown?: Countdown;
	constructor(props: ISessionTimeoutProps) {
		super(props);
		this.countDown = undefined;
		this.state = {
			sessionIdleTime: 999,
			showTimeout: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.sessionIdleTime > 0) {
			this.setState({ sessionIdleTime: nextProps.sessionIdleTime }, () => {
				this.setExpiryTime();
			});
		}
	}

	public setExpiryTime = () => {
		window.setTimeout(() => {
			this.setState({ showTimeout: true });
		}, 1000 * 60 * (this.state.sessionIdleTime / 60 - WarningVisibleMinutes));
	};

	public render() {
		return (
			<Modal
				className='session-timeout-modal'
				show={this.state.showTimeout}
				onHide={this.onLogout}>
				<Modal.Header
					closeButton
					data-test-auto='60334535-F01C-41D3-AC34-CA9BC7B804DD'>
					<Modal.Title>
						<span className='modalIcon text-secondary fa fa-clock'></span>Warning
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row
						data-test-auto='F12E9E3A-5B0D-4463-9329-750FAA9B7D12'
						className='sessionTimeoutContentWrapper'>
						<Col
							lg={3}
							className='sessionTimeoutSVGIconWrapper'>
							<SessionTimeoutSVG />
						</Col>
						<Col
							lg={9}
							className='sessionTimeoutTextWrapper'>
							<h2 style={{ color: '#337ab7' }}>Session Expiration</h2>
							<div>
								Your session will expire in{' '}
								<Countdown
									date={this.getCountDown()}
									onComplete={this.onLogout}
									ref={(instance: any) => {
										this.countDown = instance;
									}}
								/>{' '}
								Do you want to continue working?
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className='btn-white'
						data-test-auto='C0280D93-94DD-438B-9AB9-4F5BC452FE66'
						onClick={this.onLogout}>
						<i className='fa fa-sign-out-alt'></i>Logoff
					</Button>
					<Button
						variant='info'
						data-test-auto='CD93CDEF-A642-4D28-9C11-E6913BC2C2C0'
						onClick={this.onStayAlive}>
						<i className='fa fa-check-double'></i>Continue Working
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	private getCountDown = (): Date => {
		const now = new Date();
		let ret = now;
		ret.setMinutes(now.getMinutes() + WarningVisibleMinutes);
		return ret;
	};

	private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
		e.preventDefault();
		let param: any = this.props.match.params;
		if (this.countDown) {
			clearInterval(this.countDown.interval);
		}
		this.props.refreshToken(param.clientId, this.setExpiryTime);
		this.setState({ showTimeout: false });
	};

	private onLogout = () => {
		let param: any = this.props.match.params;
		localStorage.remove('isLoggedIn');
		window.location.href = '/Authentication/Logout/' + param.clientId;
	};
}
