import { injectable } from 'inversify';

export interface IRegexUtilities {
	isValid(data: any, pattern: string): boolean;
}

@injectable()
export class RegexUtilities implements IRegexUtilities {
	isValid(data: string, pattern: string): boolean {
		return new RegExp(pattern).test(data);
	}
}
