import * as React from 'react';
import HeaderComp_ShareHolder from '../../Header';
import Skeleton from 'react-loading-skeleton';

export class OTPPage extends React.Component<any, any> {
	continueRef: any = null;
	constructor(props: any) {
		super(props);
		this.state = {
			otpVal: '',
			continueState: false,
		};

		this.handleOTPChange = this.handleOTPChange.bind(this);
		this.requestCode = this.requestCode.bind(this);
		this.verifyOTP = this.verifyOTP.bind(this);
		this.handleOTPVerification = this.handleOTPVerification.bind(this);
	}

	componentDidMount() {
		this.props.requestHeaderDetails(this.props.match.params.clientId);
		this.props.getClientInfo(this.props.match.params.clientId);
	}

	requestCode() {
		this.props.generateMobileOTP(this.props.match.params.clientId);
	}

	handleOTPVerification(guid: string) {
		this.props.history.push(`/k1/account/${guid}`);
	}

	verifyOTP() {
		this.props.verifyMobileOTP(this.state.otpVal, this.props.match.params.clientId, this.handleOTPVerification);
	}

	handleOTPChange(e: any) {
		let lastChar = e.target.value[e.target.value.length - 1];

		if (
			e.target.value.length <= 6 &&
			((parseInt(lastChar) >= 0 && parseInt(lastChar) <= 9) || e.target.value.length === 0)
		) {
			if (e.target.value.length === 6) {
				this.setState({ otpVal: e.target.value, continueState: true });
			} else {
				this.setState({ otpVal: e.target.value, continueState: false });
			}
		}
	}

	render() {
		let continueClass = 'btn btn-primary continue disabled float-right';
		if (this.state.continueState) {
			continueClass = 'btn btn-primary continue float-right';
			this.continueRef.focus();
		}

		let mobileNo: string = '';
		let lastNum: string = '';

		if (this.props.OTPPageState.mobileNo !== null || this.props.OTPPageState.mobileNo !== undefined) {
			mobileNo = this.props.OTPPageState.mobileNo;
		}

		if (mobileNo.length !== 0) {
			lastNum = '{xxx}xxx-' + mobileNo.substr(6);
		}

		return (
			<>
				<HeaderComp_ShareHolder headerInfo={this.props.headerInfoState} />
				{this.props.OTPPageState.loading ? (
					<Skeleton
						circle={false}
						height={'100vh'}
						width={'100vw'}
					/>
				) : (
					<div
						className='col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page'
						style={{ backgroundColor: '#F0F0F0' }}>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container'>
							<h5 data-test-auto='BAC57E4B-7806-429B-9E44-0163145A9ACC'>Authentication</h5>
							<br />
							<strong data-test-auto='6B37FC81-36C9-4362-9E27-516FF7AC423F'>Mobile Access Code Required</strong>
							<div>
								<div
									className='margin-top-10'
									data-test-auto='C55FD8AE-04E4-40F4-A091-9A901898F5B7'>
									<p>
										{' '}
										Please select “Request Access Code” and we will send you a one-time expiring access code to your
										mobile device number:{lastNum}{' '}
									</p>
								</div>
								<div
									data-test-auto='5877E1VF4W543DKW4MY8AYKH2W'
									className='margin-top-10'>
									<p> If the mobile device number is incorrect please contact your Tax Professional.</p>
								</div>

								<div
									className='margin-top-10'
									data-test-auto='2DDA88CF-DDF5-44E1-90E9-7ABF94182032'></div>
								<div className='margin-top-50'>
									<button
										data-test-auto='724ECE16-9490-4BA3-BAC1-3B6E1631983D'
										type='button'
										className='btn btn-primary continue'
										id='btnRequestMobileAccessCode'
										onClick={this.requestCode}>
										Request Access Code
									</button>
								</div>

								<div
									className='otp-page-container-input-div margin-top-50'
									data-test-auto='211DC38A-0C44-4829-8742-09FC6C26BC10'>
									<span>Enter access code here:</span> &nbsp;
									<input
										data-test-auto='WL2PVLYGV7Y4V6M2KCECL0WT9D'
										id='otp'
										name='otp'
										type='text'
										value={this.state.otpVal}
										onChange={this.handleOTPChange}
										style={{ margin: '0 36px 0 4px' }}
										autoComplete='false'
									/>
									<span className='access-code-expiry-info'>(This code will expire in 20 minutes)</span>
								</div>

								<div className='margin-top-50  justify-content'>
									<button
										ref={(ref) => {
											this.continueRef = ref;
										}}
										type='submit'
										data-test-auto='44B7FAC9-D67F-4D5C-AAA0-5FD8212C5377'
										id='btnContinue'
										className={continueClass}
										onClick={this.verifyOTP}>
										Continue
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
export default OTPPage;
