import * as React from 'react';

export interface SvgIconProps {
	width?: number;
	height?: number;
	className?: string;
	onClick?: any;
	primaryColor?: string;
	secondaryColor?: string;
	fillColor?: string;
}

export const ToasterCloseIcon: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width || 16}
			height={props.height || 16}
			className={props.className}
			onClick={props.onClick}
			viewBox='0 0 16 16'
			fill='none'>
			<g clipPath='url(#clip0_1396_34627)'>
				<path
					d='M0.292542 0.292105C0.48007 0.104634 0.734378 -0.000681838 0.999542 -0.000681838C1.26471 -0.000681838 1.51901 0.104634 1.70654 0.292105L7.99954 6.5851L14.2925 0.292105C14.3848 0.196594 14.4951 0.120412 14.6171 0.0680032C14.7391 0.0155942 14.8704 -0.011992 15.0031 -0.0131458C15.1359 -0.0142997 15.2676 0.0110021 15.3905 0.0612829C15.5134 0.111564 15.625 0.185817 15.7189 0.27971C15.8128 0.373602 15.8871 0.485254 15.9374 0.608151C15.9876 0.731047 16.0129 0.862727 16.0118 0.995506C16.0106 1.12829 15.9831 1.25951 15.9306 1.38151C15.8782 1.50351 15.8021 1.61386 15.7065 1.7061L9.41354 7.9991L15.7065 14.2921C15.8887 14.4807 15.9895 14.7333 15.9872 14.9955C15.9849 15.2577 15.8798 15.5085 15.6944 15.6939C15.509 15.8793 15.2581 15.9845 14.9959 15.9868C14.7337 15.9891 14.4811 15.8883 14.2925 15.7061L7.99954 9.4131L1.70654 15.7061C1.51794 15.8883 1.26534 15.9891 1.00314 15.9868C0.740944 15.9845 0.490132 15.8793 0.304724 15.6939C0.119316 15.5085 0.0141467 15.2577 0.0118683 14.9955C0.00958985 14.7333 0.110384 14.4807 0.292542 14.2921L6.58554 7.9991L0.292542 1.7061C0.105071 1.51858 -0.000244141 1.26427 -0.000244141 0.999105C-0.000244141 0.73394 0.105071 0.479632 0.292542 0.292105Z'
					fill='#898D91'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1396_34627'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const SuccessIcon: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clipPath='url(#clip0_2251_2720)'>
				<path
					d='M15.9998 7.99927C15.9998 10.121 15.1569 12.1558 13.6566 13.6561C12.1563 15.1564 10.1215 15.9993 7.99976 15.9993C5.87802 15.9993 3.84319 15.1564 2.3429 13.6561C0.842611 12.1558 -0.000244141 10.121 -0.000244141 7.99927C-0.000244141 5.87754 0.842611 3.8427 2.3429 2.34241C3.84319 0.842122 5.87802 -0.000732422 7.99976 -0.000732422C10.1215 -0.000732422 12.1563 0.842122 13.6566 2.34241C15.1569 3.8427 15.9998 5.87754 15.9998 7.99927ZM12.0298 4.96927C11.9583 4.89809 11.8733 4.84204 11.7797 4.80448C11.6861 4.76692 11.5859 4.74863 11.4851 4.75068C11.3843 4.75273 11.2849 4.7751 11.1929 4.81644C11.1009 4.85777 11.0182 4.91724 10.9498 4.99127L7.47676 9.41627L5.38376 7.32227C5.24158 7.18979 5.05353 7.11766 4.85923 7.12109C4.66493 7.12452 4.47955 7.20323 4.34213 7.34065C4.20472 7.47806 4.12601 7.66344 4.12258 7.85775C4.11915 8.05205 4.19128 8.24009 4.32376 8.38227L6.96976 11.0293C7.04104 11.1004 7.12592 11.1565 7.21934 11.1941C7.31276 11.2318 7.4128 11.2502 7.5135 11.2483C7.6142 11.2465 7.71349 11.2243 7.80545 11.1833C7.89741 11.1422 7.98016 11.083 8.04876 11.0093L12.0408 6.01927C12.1768 5.87777 12.252 5.68854 12.2502 5.49223C12.2483 5.29591 12.1695 5.10815 12.0308 4.96927H12.0298Z'
					fill='#1F8747'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2251_2720'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const DangerIcon: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g
				id='icon-wrapper'
				clipPath='url(#clip0_2456_816)'>
				<path
					id='Union'
					d='M8.9817 1.56522C8.88271 1.39278 8.73998 1.24952 8.56791 1.14989C8.39584 1.05026 8.20053 0.997803 8.0017 0.997803C7.80287 0.997803 7.60756 1.05026 7.43549 1.14989C7.26343 1.24952 7.12069 1.39278 7.0217 1.56522L0.164702 13.2322C-0.292298 14.0102 0.255702 14.9992 1.1447 14.9992H14.8577C15.7467 14.9992 16.2957 14.0092 15.8377 13.2322L8.9817 1.56522ZM7.9997 4.99922C8.5347 4.99922 8.9537 5.46122 8.8997 5.99422L8.5497 9.50122C8.53794 9.63899 8.4749 9.76733 8.37306 9.86086C8.27121 9.95438 8.13797 10.0063 7.9997 10.0063C7.86143 10.0063 7.72819 9.95438 7.62635 9.86086C7.5245 9.76733 7.46146 9.63899 7.4497 9.50122L7.0997 5.99422C7.08713 5.86845 7.10105 5.74145 7.14055 5.62139C7.18005 5.50132 7.24426 5.39087 7.32905 5.29713C7.41383 5.20339 7.51731 5.12846 7.63282 5.07715C7.74833 5.02584 7.87331 4.99929 7.9997 4.99922ZM8.0017 10.9992C8.26692 10.9992 8.52127 11.1046 8.70881 11.2921C8.89634 11.4796 9.0017 11.734 9.0017 11.9992C9.0017 12.2644 8.89634 12.5188 8.70881 12.7063C8.52127 12.8939 8.26692 12.9992 8.0017 12.9992C7.73649 12.9992 7.48213 12.8939 7.2946 12.7063C7.10706 12.5188 7.0017 12.2644 7.0017 11.9992C7.0017 11.734 7.10706 11.4796 7.2946 11.2921C7.48213 11.1046 7.73649 10.9992 8.0017 10.9992Z'
					fill='#CC4A43'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2456_816'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const WarningIcon: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_7401_1164)'>
				<path
					d='M8.98195 1.56595C8.88296 1.39352 8.74022 1.25025 8.56815 1.15062C8.39608 1.051 8.20078 0.998535 8.00195 0.998535C7.80312 0.998535 7.60781 1.051 7.43574 1.15062C7.26367 1.25025 7.12094 1.39352 7.02195 1.56595L0.164946 13.233C-0.292054 14.011 0.255946 15 1.14495 15H14.8579C15.7469 15 16.2959 14.01 15.8379 13.233L8.98195 1.56595ZM7.99995 4.99995C8.53495 4.99995 8.95395 5.46195 8.89995 5.99495L8.54995 9.50195C8.53819 9.63972 8.47515 9.76806 8.3733 9.86159C8.27145 9.95511 8.13822 10.007 7.99995 10.007C7.86167 10.007 7.72844 9.95511 7.62659 9.86159C7.52474 9.76806 7.46171 9.63972 7.44995 9.50195L7.09995 5.99495C7.08738 5.86919 7.10129 5.74218 7.14079 5.62212C7.18029 5.50206 7.2445 5.3916 7.32929 5.29786C7.41408 5.20413 7.51756 5.12919 7.63307 5.07788C7.74858 5.02657 7.87355 5.00002 7.99995 4.99995ZM8.00195 11C8.26716 11 8.52152 11.1053 8.70905 11.2928C8.89659 11.4804 9.00195 11.7347 9.00195 12C9.00195 12.2652 8.89659 12.5195 8.70905 12.7071C8.52152 12.8946 8.26716 13 8.00195 13C7.73673 13 7.48238 12.8946 7.29484 12.7071C7.1073 12.5195 7.00195 12.2652 7.00195 12C7.00195 11.7347 7.1073 11.4804 7.29484 11.2928C7.48238 11.1053 7.73673 11 8.00195 11Z'
					fill='#D69F38'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7401_1164'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const SvgIconExclamationErrorCircle: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			className={props.className}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM7.30115 3.65882C7.39571 3.55752 7.52808 3.5 7.66667 3.5H8.33333C8.47192 3.5 8.60428 3.55752 8.69885 3.65882C8.79341 3.76013 8.84168 3.89614 8.83215 4.0344L8.49881 8.86773C8.48073 9.1299 8.26279 9.33333 8 9.33333C7.73721 9.33333 7.51926 9.1299 7.50118 8.86773L7.16785 4.0344C7.15832 3.89614 7.20659 3.76013 7.30115 3.65882ZM9 11.6667C9 12.2189 8.55229 12.6667 8 12.6667C7.44772 12.6667 7 12.2189 7 11.6667C7 11.1144 7.44772 10.6667 8 10.6667C8.55229 10.6667 9 11.1144 9 11.6667Z'
				fill='#CC4A43'
			/>
		</svg>
	);
};

export const InvalidSVG: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			width='200'
			height='200'
			viewBox='0 0 200 200'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle
				cx='100'
				cy='100'
				r='100'
				fill='#E6EBF0'
			/>
			<path
				d='M142.479 130.608V127.914C142.479 124.146 140.967 120.532 138.277 117.868C135.586 115.203 131.937 113.706 128.132 113.706C124.328 113.706 120.679 115.203 117.988 117.868C115.298 120.532 113.786 124.146 113.786 127.914V130.608C112.642 130.919 111.632 131.592 110.91 132.524C110.188 133.456 109.793 134.596 109.786 135.771V154.274C109.788 155.444 110.259 156.565 111.094 157.392C111.93 158.219 113.062 158.684 114.243 158.686H142.022C143.203 158.684 144.335 158.219 145.171 157.392C146.006 156.565 146.477 155.444 146.479 154.274V135.771C146.473 134.596 146.078 133.455 145.356 132.523C144.633 131.591 143.623 130.918 142.479 130.608ZM128.142 118.333C130.706 118.337 133.165 119.347 134.979 121.143C136.792 122.939 137.812 125.374 137.816 127.914V129.761C134.611 129.286 131.376 129.034 128.136 129.007C124.893 129.034 121.656 129.286 118.449 129.761V127.914C118.455 125.372 119.479 122.935 121.296 121.139C123.113 119.343 125.575 118.334 128.142 118.333ZM114.464 154.274V135.771C114.462 135.585 114.526 135.405 114.644 135.26C114.762 135.116 114.927 135.017 115.111 134.98C119.413 134.146 123.783 133.693 128.167 133.625C132.543 133.694 136.905 134.147 141.201 134.98C141.385 135.017 141.55 135.116 141.668 135.26C141.786 135.405 141.849 135.585 141.848 135.771V141.981C141.859 142.065 141.876 142.148 141.897 142.23L142.053 154.068L114.464 154.274Z'
				fill='#B4C3D3'
			/>
			<path
				d='M133.719 61.0332H61.5861C58.203 61.0381 54.9598 62.3905 52.5678 64.7938C50.1758 67.1971 48.8303 70.4552 48.8262 73.8535V121.6C48.8303 124.999 50.1758 128.257 52.5678 130.66C54.9598 133.063 58.203 134.416 61.5861 134.421H95.2159C95.8326 134.421 96.4239 134.175 96.86 133.737C97.296 133.299 97.541 132.705 97.541 132.085C97.541 131.466 97.296 130.872 96.86 130.434C96.4239 129.996 95.8326 129.75 95.2159 129.75H61.5861C59.4357 129.746 57.3744 128.887 55.8541 127.359C54.3339 125.831 53.4788 123.76 53.4763 121.6V86.923H141.829V102.674C141.829 103.293 142.074 103.887 142.51 104.325C142.946 104.763 143.537 105.009 144.154 105.009C144.771 105.009 145.362 104.763 145.798 104.325C146.234 103.887 146.479 103.293 146.479 102.674V73.8535C146.475 70.4552 145.129 67.1971 142.737 64.7938C140.345 62.3905 137.102 61.0381 133.719 61.0332ZM53.4763 82.252V73.8442C53.4812 71.6858 54.3374 69.6173 55.8574 68.0916C57.3774 66.5659 59.4374 65.7075 61.5861 65.7042H133.719C135.869 65.7075 137.931 66.5672 139.451 68.0949C140.971 69.6226 141.826 71.6935 141.829 73.8535V82.2613L53.4763 82.252Z'
				fill='#B4C3D3'
			/>
			<path
				d='M63.6221 115.186C63.6221 115.947 63.8477 116.69 64.2704 117.323C64.6931 117.956 65.2939 118.449 65.9968 118.74C66.6998 119.031 67.4733 119.107 68.2195 118.959C68.9657 118.81 69.6512 118.444 70.1892 117.906C70.7272 117.368 71.0936 116.683 71.242 115.936C71.3904 115.19 71.3143 114.417 71.0231 113.714C70.7319 113.011 70.2389 112.41 69.6062 111.987C68.9736 111.564 68.2298 111.339 67.469 111.339C66.4487 111.339 65.4702 111.744 64.7488 112.466C64.0274 113.187 63.6221 114.166 63.6221 115.186Z'
				fill='#B4C3D3'
			/>
			<path
				d='M78.4183 115.186C78.4183 115.947 78.6613 116.69 79.1165 117.323C79.5718 117.956 80.2188 118.449 80.9758 118.74C81.7328 119.031 82.5658 119.107 83.3694 118.959C84.173 118.81 84.9112 118.444 85.4906 117.906C86.07 117.368 86.4646 116.683 86.6244 115.936C86.7843 115.19 86.7022 114.417 86.3887 113.714C86.0751 113.011 85.5441 112.41 84.8628 111.987C84.1815 111.564 83.3806 111.339 82.5612 111.339C81.4624 111.339 80.4087 111.744 79.6317 112.466C78.8548 113.187 78.4183 114.166 78.4183 115.186Z'
				fill='#B4C3D3'
			/>
		</svg>
	);
};

export const SvgIconAccessCode: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='82.44'
			height='70'
			viewBox='0 0 82.44 70'
			fill='none'
			className={props.className}>
			<path
				d='M18.7748 28.6228C20.9342 28.6228 22.6848 26.8723 22.6848 24.7129C22.6848 22.5535 20.9342 20.8029 18.7748 20.8029C16.6154 20.8029 14.8649 22.5535 14.8649 24.7129C14.8649 26.8723 16.6154 28.6228 18.7748 28.6228Z'
				fill='#05386B'
				stroke='#05386B'
			/>
			<path
				d='M34.4034 28.6228C36.5628 28.6228 38.3133 26.8723 38.3133 24.7129C38.3133 22.5535 36.5628 20.8029 34.4034 20.8029C32.244 20.8029 30.4934 22.5535 30.4934 24.7129C30.4934 26.8723 32.244 28.6228 34.4034 28.6228Z'
				fill='#05386B'
				stroke='#05386B'
			/>
			<path
				d='M50.0325 28.6228C52.1919 28.6228 53.9425 26.8723 53.9425 24.7129C53.9425 22.5535 52.1919 20.8029 50.0325 20.8029C47.8731 20.8029 46.1226 22.5535 46.1226 24.7129C46.1226 26.8723 47.8731 28.6228 50.0325 28.6228Z'
				fill='#05386B'
				stroke='#05386B'
			/>
			<path
				d='M65.6612 28.6228C67.8206 28.6228 69.5711 26.8723 69.5711 24.7129C69.5711 22.5535 67.8206 20.8029 65.6612 20.8029C63.5018 20.8029 61.7512 22.5535 61.7512 24.7129C61.7512 26.8723 63.5018 28.6228 65.6612 28.6228Z'
				fill='#05386B'
				stroke='#05386B'
			/>
			<path
				d='M71.9634 0.5H71.9628L12.473 0.5L12.4724 0.5C9.29823 0.503614 6.25511 1.76615 4.01063 4.01063C1.76615 6.2551 0.503614 9.29823 0.5 12.4724V12.473L0.5 36.9525L0.5 36.953C0.503614 40.1272 1.76615 43.1703 4.01063 45.4148C6.25511 47.6593 9.29823 48.9218 12.4724 48.9255H12.473H40.3624C41.0156 48.9255 41.642 48.666 42.1039 48.2041C42.5657 47.7423 42.8252 47.1159 42.8252 46.4627C42.8252 45.8095 42.5657 45.1831 42.1039 44.7213C41.642 44.2594 41.0156 43.9999 40.3624 43.9999H12.4737C10.6052 43.9974 8.81395 43.254 7.49273 41.9327C6.17158 40.6116 5.42819 38.8205 5.42552 36.9521C5.42552 36.952 5.42551 36.9519 5.42551 36.9518V12.4737C5.42551 12.4736 5.42552 12.4734 5.42552 12.4733C5.42819 10.605 6.17158 8.81387 7.49273 7.49273C8.81387 6.17158 10.605 5.42819 12.4733 5.42551C12.4734 5.42551 12.4736 5.42551 12.4737 5.42551H71.9622C71.9623 5.42551 71.9624 5.42551 71.9625 5.42551C73.8309 5.42819 75.6219 6.17158 76.9431 7.49273C78.2643 8.81395 79.0077 10.6052 79.0103 12.4737V31.8388C79.0103 32.492 79.2698 33.1184 79.7316 33.5803C80.1935 34.0421 80.8199 34.3016 81.4731 34.3016C82.1262 34.3016 82.7526 34.0421 83.2145 33.5803C83.6763 33.1184 83.9358 32.492 83.9358 31.8388V12.473V12.4724C83.9322 9.29823 82.6697 6.2551 80.4252 4.01063C78.1807 1.76615 75.1376 0.503614 71.9634 0.5Z'
				fill='#05386B'
				stroke='#05386B'
			/>
			<path
				d='M65.7082 71.5C66.1363 71.5007 66.5623 71.4402 66.9733 71.3204L66.9771 71.3192C72.5067 69.6581 76.3057 66.9928 78.879 63.9058C81.4497 60.822 82.7759 57.3429 83.4029 54.0819C84.6538 47.5749 83.1311 41.8812 83.0942 41.7435V41.7435L83.0934 41.7404C82.9433 41.1931 82.6078 40.7148 82.1442 40.3873C81.6849 40.0629 81.1282 39.9065 80.5674 39.944C76.1545 40.002 71.8254 38.7366 68.1382 36.311C67.4188 35.8293 66.5725 35.5721 65.7066 35.5721C64.8404 35.5721 63.9937 35.8296 63.274 36.3117C59.9354 38.5283 56.0522 39.7857 52.048 39.9468L52.0458 39.9469C51.0558 39.9911 50.1101 40.3699 49.3635 41.0215C48.6169 41.6732 48.1136 42.5589 47.9359 43.5338L47.9359 43.5338L47.9354 43.5369C47.492 46.0589 46.906 51.5738 48.7873 57.1781C50.6794 62.8142 55.0559 68.5001 64.4441 71.3192L64.447 71.3201C64.8566 71.4404 65.2813 71.5009 65.7082 71.5ZM65.7082 71.5C65.7078 71.5 65.7074 71.5 65.707 71.5L65.7079 71L65.7092 71.5C65.7089 71.5 65.7085 71.5 65.7082 71.5ZM65.6653 40.5638C69.5751 43.0918 74.0747 44.5602 78.7224 44.8253C79.1062 47.1405 79.446 51.322 78.0287 55.4997C76.5484 59.8629 73.1381 64.2679 65.7106 66.539C58.5026 64.3349 55.0698 60.124 53.5186 55.8433C52.0233 51.7171 52.2619 47.4815 52.6584 44.8266C57.2937 44.5788 61.7816 43.1083 65.6653 40.5638Z'
				fill='#88C656'
				stroke='#88C656'
			/>
		</svg>
	);
};
