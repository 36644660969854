import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { IWelcomeMessageModal, initialWelcomeMessageModal } from '../../core/domain/viewModels/WelcomeMessage';
import { TelemetryLogger } from '../../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestWelcomeMessageAction {
	type: actionTypes.WELCOMEMESSAGE_REQUEST;
	id: string;
}
interface ResponseWelcomeMessageAction {
	type: actionTypes.WELCOMEMESSAGE_RESPONSE;
	data: IWelcomeMessageModal;
}
interface FailureWelcomeMessageAction {
	type: actionTypes.WELCOMEMESSAGE_FAILURE;
	id: string;
}

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction = RequestWelcomeMessageAction | ResponseWelcomeMessageAction | FailureWelcomeMessageAction;

export const actionCreators = {
	requestWelcomeMessageInfo:
		(id: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let state = getState();
			dispatch({ type: actionTypes.WELCOMEMESSAGE_REQUEST, id: id });
			return initializeAxios(id)
				.get<IWelcomeMessageModal>('/api/Helper/GetWelcomeMessageData/' + id)
				.then(function (response: AxiosResponse<IWelcomeMessageModal>) {
					dispatch({
						type: actionTypes.WELCOMEMESSAGE_RESPONSE,
						data: response.data,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response?.statusText ?? error.message,
						statusType: StatusType.Error,
					});
					dispatch({ type: actionTypes.WELCOMEMESSAGE_FAILURE, id: id });
					logger.trackWarning(`k1 shareholder requestWelcomeMessageInfo failed with error ${error.message}`);
				});
		},
};

export const reducer: Reducer<IWelcomeMessageModal> = (
	state: IWelcomeMessageModal = initialWelcomeMessageModal,
	incomingAction: Action,
) => {
	const action = incomingAction as DispatchAction;
	var data = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.WELCOMEMESSAGE_REQUEST:
			return initialWelcomeMessageModal;
		case actionTypes.WELCOMEMESSAGE_RESPONSE:
			return action.data;
		default:
			return state || initialWelcomeMessageModal;
	}
};
