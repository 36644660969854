import * as React from 'react';
import HeaderComp_ShareHolder from '../Header';
import Skeleton from 'react-loading-skeleton';
import Footer from '../Layout/Footer';
import OTPMobile from './OTPMobile';
import { DisplayError } from '../Common/Constants';

export class OTPPage extends React.Component<any, any> {
	continueRef: any = null;

	constructor(props: any) {
		super(props);
		this.state = {
			otpVal: '',
			continueState: false,
			isInitialOTPSent: false,
			otpError: {
				error: false,
				message: '',
			},
		};

		this.handleOTPChange = this.handleOTPChange.bind(this);
		this.requestCode = this.requestCode.bind(this);
		this.verifyOTP = this.verifyOTP.bind(this);
		this.handleOTPVerification = this.handleOTPVerification.bind(this);
	}

	componentDidMount() {
		this.props.requestHeaderDetails(this.props.match.params.clientId);
		this.props.getClientInfo(this.props.match.params.clientId);
	}

	gotToErrorPage = (result: any) => {
		let errorDesc = result.errorDescription;
		let errorCode = result.errorCode;
		if (errorCode && DisplayError.includes(errorCode)) {
			this.props.history.push('/Error/' + errorDesc);
		} else {
			this.setState({
				otpError: { error: true, message: errorDesc },
				otpVal: '',
			});
		}
	};

	handleError = () => {
		this.props.history.push('/Error');
	};

	requestCode() {
		if (!this.state.isInitialOTPSent) {
			this.setState({ isInitialOTPSent: true });
		}
		this.props.generateMobileOTP(this.props.match.params.clientId);
	}

	handleOTPVerification(guid: string) {
		this.props.history.push(`/k1/Downloads/${guid}`);
	}

	verifyOTP() {
		if (this.state.continueState) {
			this.props.verifyMobileOTP(
				this.state.otpVal,
				this.props.match.params.clientId,
				this.handleOTPVerification,
				this.gotToErrorPage,
			);
		}
	}

	handleOTPChange(e: any) {
		this.setState({ otpError: { error: false, message: '' } });
		let otpValue = e;
		if (otpValue.length <= 6 && (!isNaN(otpValue) || otpValue.length === 0)) {
			if (otpValue.length === 6) {
				this.setState({ otpVal: otpValue, continueState: true });
			} else {
				this.setState({ otpVal: otpValue, continueState: false });
			}
		}
	}

	render() {
		return (
			<>
				<HeaderComp_ShareHolder headerInfo={this.props.headerInfoState} />
				{this.props.OTPPageState.loading === true ? (
					<Skeleton
						circle={false}
						height={'100vh'}
						width={'100vw'}
					/>
				) : (
					<OTPMobile
						match={this.props.match}
						history={this.props.history}
						headerInfoState={this.props.headerInfoState}
						OTPPageState={this.props.OTPPageState}
						otpVal={this.state.otpVal}
						continueState={this.state.continueState}
						isInitialOTPSent={this.state.isInitialOTPSent}
						otpError={this.state.otpError}
						handleOTPChange={this.handleOTPChange}
						requestCode={this.requestCode}
						verifyOTP={this.verifyOTP}
					/>
				)}
				<Footer />
			</>
		);
	}
}
export default OTPPage;
