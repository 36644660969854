import { InvalidSVG } from '../../assets/images/SVGIcons';
import * as React from 'react';
import Footer from '../Layout/Footer';
interface InvalidLinkState {}

export class InvalidLink extends React.Component<{}, InvalidLinkState> {
	constructor(props: any, states: InvalidLinkState) {
		super(props, states);
	}

	public render() {
		return (
			<div>
				<div className='invalidContainer'>
					<div className='invalidHeader'></div>
					<div className='invalidBodyContainer'>
						<div className='invalidSVG'>
							<InvalidSVG />
						</div>
						<div className='invalidMessage'>
							The link to access your file(s) has been locked.
							<br />
							Please contact your firm for further assistance.
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
