import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { match } from 'react-router';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Disclosure } from '../K1Download/Disclosure';
import { K1AttachmentInfoModel } from '../../core/domain/viewModels/IK1DownloadViewModel';
import PaperClip from '../../assets/images/PaperClip';
import Download from '../../assets/images/Download';

interface DownloadPageProps {
	taxyear: number;
	downloadK1Package: (clientGuid: string) => void;
	downloadK1Instruction: (clientGuid: string) => void;
	downloadK1Attachment: (uri: string, fileName?: string) => void;
	k1Name: string;
	k1Instruction: string;
	k1Attachments: K1AttachmentInfoModel[];
	param: match;
	instructionLoader: boolean;
	packageLoader: boolean;
}

const DownloadPage: React.FunctionComponent<DownloadPageProps> = ({
	taxyear,
	downloadK1Instruction,
	downloadK1Package,
	downloadK1Attachment,
	k1Instruction,
	k1Name,
	k1Attachments,
	param,
	instructionLoader,
	packageLoader,
}) => {
	const k1PackageName: string = k1Name + ' K-1 Package';

	const renderK1Attachments = () => {
		return k1Attachments.map((k1Attachment, index) => {
			return (
				<div className='pdfBlock'>
					<span className='paperClipIcon'>
						<PaperClip />
					</span>
					<span className='pdfBlockText overflow-ellipsis'>{k1Attachment.fileName}</span>
					<span
						className='downloadIcon'
						onClick={() => downloadK1Attachment(k1Attachment.filePath, k1Attachment.fileName)}
						data-test-auto={`86653f49-404c-4fd0-bb5b-39cdf607b9f9${index}`}>
						<Download />
					</span>
				</div>
			);
		});
	};

	return (
		<main className='downloadPageContainer'>
			<section className='downloadPageInnerContainer'>
				{(k1Name && k1Name !== '') || (k1Instruction && k1Instruction != '') ? (
					<>
						<h2 className='downloadSectionHeader'>Your {taxyear} Tax Documents</h2>
						<div className='downloadSectionText'>Please select a file to download</div>
						<div className='downloadDocumentsContainer'>
							<div className='packageBlock'>
								<span className='paperClipIcon'>
									<PaperClip />
								</span>
								<span className='packageBlockText overflow-ellipsis'>{k1PackageName}</span>
								{!packageLoader ? (
									<span
										className='downloadIcon'
										onClick={() => {
											downloadK1Package((param.params as any).clientId);
										}}
										data-test-auto='B8B2F803-55FC-4C7A-B9D7-D9AB9591DA41'>
										<Download />
									</span>
								) : (
									<FontAwesomeIcon
										data-test-auto='34ae2fce-7034-4f77-8cde-400ff211aa85'
										icon={faSpinner}
										spin={packageLoader}
									/>
								)}
							</div>
							{k1Instruction && (
								<div className='pdfBlock'>
									<span className='paperClipIcon'>
										<PaperClip />
									</span>
									<span className='pdfBlockText overflow-ellipsis'>{k1Instruction}</span>
									{!instructionLoader ? (
										<span
											className='downloadIcon'
											onClick={() => downloadK1Instruction((param.params as any).clientId)}
											data-test-auto='25F83BEB-15BB-4AFE-AC44-8BB57E9FF17E'>
											<Download />
										</span>
									) : (
										<FontAwesomeIcon
											data-test-auto='e11abf0c-bea0-4253-b096-a8bc7a365a6c'
											icon={faSpinner}
											spin={instructionLoader}
										/>
									)}
								</div>
							)}
							{k1Attachments.length > 0 && renderK1Attachments()}
						</div>
						<Disclosure />
					</>
				) : (
					<Shimmer height={200} />
				)}
			</section>
		</main>
	);
};
export default DownloadPage;
